import React from 'react';
import {
    ReferenceInput,
    SelectInput,
	FunctionField,
} from 'react-admin';
import { Edit } from '../../Components/Edit';
import { Form, Tabs, TabPanel } from '../../Components/Form';

import TelemetryPackagesSection from './TelemetryPackagesSection';
import TelemetryLogsSection from './TelemetryLogsSection';

const DeviceEdit = ({ ...props }) => (
    <Edit {...props} undoable={false}>
        <Form variant="outlined" description={false}>
			<Tabs>
				<TabPanel title="Device info" p={2}>
					<ReferenceInput label="Organization" source="organization.id" reference="admin/organizations" fullWidth>
						<SelectInput source="name" />
					</ReferenceInput>
					<FunctionField label="CPU"  render={ record => record.cpu  && `${record.cpu.model} (${record.cpu.frequency})` } />
					<FunctionField label="GPU"  render={ record => record.gpu  && record.gpu.map( i => ( <p>{i}</p> ) ) } />
					<FunctionField label="Disk" render={ record => record.disk && `${record.disk.model} (${record.disk.serial})` } />
					<FunctionField label="BIOS" render={ record => record.bios && `${record.bios.vendor} (${record.bios.version}) ${record.bios.date}` } />
				</TabPanel>
				<TabPanel title="Current packages">
					<TelemetryPackagesSection />
				</TabPanel>
				<TabPanel title="Logs">
					<TelemetryLogsSection />
				</TabPanel>
			</Tabs>
		</Form>
    </Edit>
);

export default DeviceEdit;