import React from 'react';
import { NumberField } from 'react-admin';
import get from 'lodash/get';

const BalanceField = ( { style = { color: "black" }, ...props } ) =>
{
	const value = get( props.record, props.source );

	if( value < 0 )
	{
		style.color = "red";
	}

	return ( <NumberField {...props}  style={style} /> );
};

BalanceField.defaultProps =
{
    addLabel: true,
};

export default BalanceField;