import { w3cwebsocket } from "websocket";
import { AUTH_COOKIE_NAME } from "../Auth/AuthProvider";
import * as Cookie from "../Cookie";

export class WebSocket
{
	options =
	{
		autoReconnect: true,
		autoReconnectInterval: 5000,
	};

	constructor( options = {} )
	{
		this.options = { ...this.options, ...options };
		this.promiseHandlers = {};
		this.eventHandlers   = {};
	}

	Open()
	{
		if( this.connection != null )
		{
			this.connection.onopen = null;
			this.connection.onclose = null;
			this.connection.onerror = null;
			this.connection.onmessage = null;
			this.connection = null;
		}

		return new Promise(
			( resolve, reject ) =>
			{
				const token = Cookie.Get( AUTH_COOKIE_NAME );

				if( typeof( token ) === "undefined" )
				{
					return reject( "unauthorized" );
				}

				const proto = window.location.protocol === "https:" ? "wss:" : "ws:";

				const uri = `${proto}//${window.location.hostname}/api/ws/?token=` + token;

				this.connection = new w3cwebsocket( uri );

				this.connection.onclose   = ( e ) => this._onDisconnected( e );
				this.connection.onerror   = ( e ) => this._onError( e );
				this.connection.onmessage = ( e ) => this._onMessageReceived( JSON.parse( e.data ) );

				this.connection.onopen = () =>
				{
					this._onConnected();

					resolve();
				}
			}
		);
	}

	Close()
	{
		this.promiseHandlers = this.eventHandlers = null;

		try
		{
			this.connection.close();
		}
		catch( e )
		{
			console.error( e );
		}
	}

	IsConnected()
	{
		return this.GetReadyState() === w3cwebsocket.OPEN;
	}

	GetReadyState()
	{
		return this.connection && this.connection.readyState;
	}
	
	Emit( method, ...args )
	{
		const message =
		{
			id     : "_" + ( new Date().getTime() + ( Math.random() * 10 ) ).toString( 36 ),
			method : method,
			params : [ ...args ],
		};

		try
		{
			this.connection.send( JSON.stringify( message ) );
		}
		catch( e )
		{
			return Promise.reject( e.message );
		}

		return new Promise( ( resolve, reject ) => this.promiseHandlers[ message.id ] = { resolve, reject } );
	}

	BindEvent( eventName, attachedTo, callback, thisCall )
	{
		if( typeof( this.eventHandlers[ eventName ] ) === "undefined" )
		{
			this.eventHandlers[ eventName ] = [];
		}

		this.eventHandlers[ eventName ].push( { callback, thisCall, attachedTo } );
	}

	AddEventListener( eventName, attachedTo, callback, thisCall )
	{
		this.BindEvent( eventName, attachedTo, callback, thisCall );

		if( eventName === "OnClientConnected" )
		{
			return Promise.resolve();
		}

		return this.Emit( "AddEventListener", eventName, attachedTo );
	}

	RemoveEventListener( eventName, attachedTo )
	{
		this.eventHandlers[ eventName ] = this.eventHandlers[ eventName ].filter( x => x.attachedTo !== attachedTo );

		return this.Emit( "RemoveEventListener", eventName, attachedTo );
	}
	
	_onMessageReceived( message )
	{
		try
		{
			if( typeof( message.id ) !== "undefined" )
			{
				if( typeof( message.result ) !== "undefined" )
				{
					return this.__resolve( message );
				}
				
				if( typeof( message.error ) !== "undefined" )
				{
					return this.__reject( message );
				}
			}

			if( typeof( message.data ) !== "undefined" && typeof( message.data.event_name ) !== "undefined" )
			{
				return this.__event( message );
			}

			throw new Error( "unknown message: " + JSON.stringify( message ) );
		}
		catch( e )
		{
			console.error( `[ERROR]: ${e}` );
		}
	}

	__resolve( message )
	{
		const { id, result } = message;

		if( typeof( this.promiseHandlers[ id ] ) !== "undefined" )
		{
			this.promiseHandlers[ id ].resolve.call( null, result );
			this.promiseHandlers[ id ] = null;
		}
	}

	__reject( message )
	{
		const { id, error } = message;

		if( typeof( this.promiseHandlers[ id ] ) !== "undefined" )
		{
			this.promiseHandlers[ id ].reject.call( null, error.message );
			this.promiseHandlers[ id ] = null;
		}
	}

	__event( message )
	{
		const { data } = message;

		if( typeof( this.eventHandlers[ data.event_name ] ) !== "undefined" )
		{
			this.eventHandlers[ data.event_name ].map( ( { callback, thisCall } ) => callback.call( thisCall, data ) );
		}
	}

	_reconnect( e )
	{
		console.log( "reconnecting..." );

		setTimeout( () => this.Open(), this.options.autoReconnectInterval );
	}

	_restoreEvents()
	{
		for( let eventName in this.eventHandlers )
		{
			if( eventName === "OnClientConnected" )
			{
				continue;
			}

			for( let { attachedTo } of this.eventHandlers[ eventName ] )
			{
				this.Emit( "AddEventListener", eventName, attachedTo );
			}
		}
	}

	_onConnected()
	{
		console.log( "connected" );

		this._restoreEvents();
		this.OnConnected();
	}

	_onDisconnected( e )
	{
		console.log( "connection closed: " + e.code );

		this.OnDisconnected( e );

		if( e.code !== 1000 && e.code !== 1001 && this.options.autoReconnect )
		{
			this._reconnect( e );
		}
	}
	
	_onError( e )
	{
		this.OnError( e );
	}

	OnConnected() {}

	OnDisconnected( e ) {}

	OnError( e ) {}
}
