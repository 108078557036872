import React, { useState, useCallback, useEffect } from 'react';
import { CheckboxGroupInput, useVersion, useDataProvider } from 'react-admin';

export default ( { ...props } ) =>
{
	const [ roles, setRoles ] = useState( [] );
	const dataProvider = useDataProvider();
	const version = useVersion();

	const fetchRoles = useCallback(
		async () =>
		{
			const { data } = await dataProvider.getList( 'admin/roles',
				{
					filter: {},
					sort: { field: 'id', order: 'ASC' },
					pagination: { page: 1, perPage: 50 },
				}
			);

			setRoles( data );
		},
		[ dataProvider ]
	);

	useEffect( () => { fetchRoles() }, [ version ] ); // eslint-disable-line react-hooks/exhaustive-deps

	return ( <CheckboxGroupInput {...props} label="Roles" source="roles_ids" choices={roles} /> );
};