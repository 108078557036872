import React, { Fragment } from 'react';
import { Admin, Resource } from 'react-admin';

import CssBaseline from '@material-ui/core/CssBaseline';

import { Login, Layout } from './Layout';
import { Dashboard } from './Dashboard';
import customRoutes from './Routes';
import customReducers from './Reducers';

import organizations from './Entity/Organizations';
import devices       from './Entity/Devices';
import users         from './Entity/Users';
import roles         from './Entity/Roles';

import resources     from './Entity/Resources';
import products      from './Entity/Products';
import categories    from './Entity/Categories';
import tariffs       from './Entity/Tariffs';
import tariffOptions from './Entity/Tariffs/Options';

import authProvider from './Auth/AuthProvider';
import DataProvider from './Services/DataProvider';

const App = () =>
{
	return (
		<Fragment>
			<CssBaseline />
			<Admin
				title=""
				dataProvider={DataProvider}
				customReducers={customReducers}
				customRoutes={customRoutes}
				authProvider={authProvider}
				dashboard={Dashboard}
				loginPage={Login}
				layout={Layout}
			>
				<Resource name="admin/resources" {...resources} />
				<Resource name="admin/products/categories" {...categories} />
				<Resource name="admin/products" {...products} />
				<Resource name="admin/tariffs/options" {...tariffOptions} />
				<Resource name="admin/tariffs" {...tariffs} />

				<Resource name="admin/users" {...users} />
				<Resource name="admin/organizations/balance" />
				<Resource name="admin/organizations" {...organizations} />
				<Resource name="admin/devices" {...devices} />

				<Resource name="admin/resources/items" />
				<Resource name="admin/sessions" />
				<Resource name="admin/roles" {...roles} />

				<Resource name="admin/telemetry" />
				<Resource name="admin/packages" />
				<Resource name="admin/packages/versions" />
			</Admin>
		</Fragment>
	);
};

export default App;
