import 'typeface-roboto';
import pink from '@material-ui/core/colors/pink';

const layoutOverrides =
{
	MuiMenuItem:
	{
		root:
		{
			fontWeight: 300,
		},
		dense:
		{
			fontWeight: 300,
		}
	},
	MuiListItemIcon:
	{
		root:
		{
			color: "inherit",
		}
	},
	MuiOutlinedInput:
	{
		root:
		{
			borderRadius: 0,
		}
	},
}

export const darkTheme =
{
	sidebar:
	{
		width: 260,
		closedWidth: 50,
	},
    palette:
	{
        type: 'dark',
    },
	typography:
	{
        fontFamily: 'Nunito, Helvetica, sans-serif'
    },
	overrides:
	{
		...layoutOverrides,
	}
};

export const lightTheme =
{
	sidebar:
	{
		width: 260,
		closedWidth: 50,
	},
    palette:
	{
		primary: pink,
        secondary:
		{
            light: '#5f5fc4',
            main: '#3c8dbc',
            dark: '#001064',
            contrastText: '#fff',
        },
		background:
		{
			body: "#eee",
		},
    },
	typography:
	{
        fontFamily: 'Nunito, Helvetica, sans-serif'
    },
	overrides:
	{
		...layoutOverrides,
	}
};