import React from 'react';
import
{
	ReferenceManyField,
	Datagrid,
	TextField,
	NumberField,
} from 'react-admin';

import RevokeButton from '../IdentitySessions/RevokeButton';

const DeviceShow = props => (
	<ReferenceManyField {...props} reference="admin/sessions" target="IdentityID" sort={{ field: 'id', order: 'ASC' }} addLabel={false} fullWidth={true}>
		<Datagrid>
			<NumberField source="id" />
			<TextField source="ip" />
			<TextField source="created_at" />
			<TextField source="used_at" />
			<RevokeButton />
		</Datagrid>
	</ReferenceManyField>
);

export default DeviceShow;