import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, makeStyles, IconButton, DialogContentText } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { FetchJson } from "../../Services";

const useStyles = makeStyles( ( theme ) => (
{
	root:
	{
		margin: 0,
		padding: theme.spacing( 2 ),
	},
	closeButton:
	{
		position: 'absolute',
		right: theme.spacing( 1 ),
		top: theme.spacing( 1 ),
		color: theme.palette.grey[500],
	},
} ) );

export default ( { open, record, device, onClose } ) =>
{
	const classes = useStyles();
	const [ state, setState ] = useState( {} );

	if( record == null )
	{
		return null;
	}

	FetchJson( `/admin/devices/${device.id}/telemetry/logs/${record.id}/` )
		.then( data => setState( data ) );

	return (
		<Dialog open={open} onClose={onClose} scroll="paper" maxWidth="lg" fullWidth>
			<DialogTitle>{state.name} ({state.date})
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
				  <CloseIcon />
				</IconButton>
			</DialogTitle>

			<DialogContent dividers>
				<DialogContentText><pre>{state.text}</pre></DialogContentText>
			</DialogContent>
			
			<DialogActions>
				<Button onClick={onClose} color="primary">Close</Button>
			</DialogActions>
		</Dialog>
    );
};
