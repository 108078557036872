import { ON_CLIENT_CONNECTED } from "./WebSocketSaga";
import { ON_IDENTITY_STATUS_CHANGED, IDENTITY_STATUS_OFFLINE } from "../Entity/Users/SagaActions";

const websocketData = { identity: [] };

function isOnline( id )
{
	return getOnlineStatus( id ) !== IDENTITY_STATUS_OFFLINE;
}

function getOnlineStatus( id )
{
	if( typeof( websocketData.identity[ id ] ) === "undefined" )
	{
		websocketData.identity[ id ] = { online: IDENTITY_STATUS_OFFLINE };
	}

	return websocketData.identity[ id ].online;
}

function setOnline( id, val )
{
	getOnlineStatus( id );

	websocketData.identity[ id ].online = val;
}

export default ( previousState = { identity: { isOnline, getOnlineStatus } }, { type, payload } ) =>
{
	if( type === ON_IDENTITY_STATUS_CHANGED )
	{
		const { id, status } = payload;

		setOnline( id, status );

		return { identity: { isOnline, getOnlineStatus } };
	}

	if( type === ON_CLIENT_CONNECTED )
	{
		websocketData.identity = [];

		const { online_identities } = payload;

		online_identities.map( i => setOnline( i.id, i.status ) );

		return { identity: { isOnline, getOnlineStatus } };
	}

	return previousState;
};