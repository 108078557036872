import { fetchUtils } from 'react-admin';
import defaultDataProvider from 'ra-data-simple-rest';
import * as Cookie from "../Cookie";
import { AUTH_COOKIE_NAME } from "../Auth/AuthProvider";

const baseUrl = process.env.PUBLIC_URL + "/api";

const convertFileToBase64Async = file => new Promise(
	( resolve, reject ) =>
	{
		const reader = new FileReader();

		reader.readAsDataURL( file.rawFile );
		reader.onload = () => resolve( reader.result );
		reader.onerror = reject;
	}
);

const UploadFeature = ( requestHandler ) => ( resource, params ) =>
{
	if( params.data.avatar || params.data.thumbnail )
	{
		const requestFileHandler = ( base64image ) => requestHandler( resource,
			{
				...params,
				data:
				{
					...params.data,
					file: base64image,
				},
			}
		);
		
		const image = params.data.avatar || params.data.thumbnail;

		if( image.rawFile instanceof File )
		{
			return convertFileToBase64Async( image ).then( requestFileHandler );
		}
		else
		{
			return requestFileHandler( image.id );
		}
	}

	return requestHandler( resource, params );
};

const httpClient = ( url, options = {} ) =>
{
	if( !options.headers )
	{
		options.headers = new Headers( { Accept: 'application/json' } );
	}

	options.headers.set( 'Authorization', 'Bearer ' + Cookie.Get( AUTH_COOKIE_NAME ) );

	return fetchUtils.fetchJson( url, options );
}

const DataProvider = defaultDataProvider( baseUrl, httpClient );

DataProvider.update = UploadFeature( DataProvider.update );
DataProvider.create = UploadFeature( DataProvider.create );

export default DataProvider;

export const Fetch = ( url = "/", options = { method: 'GET' } ) =>
{
	if( !options.headers )
	{
		const token = Cookie.Get( AUTH_COOKIE_NAME );

		if( !token )
		{
			console.error( "Fetch failed: Authorization token undefined" );

			return Promise.reject( "Fetch failed: Authorization token undefined" );
		}

		options.headers =
		{
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + token,
		};
	}

	if( url[ 0 ] !== '/' )
	{
		url = "/" + url;
	}

	return fetch( baseUrl + url, options )
		.then( x => x.ok ? Promise.resolve( x ) : Promise.reject( x ) );
};

export const FetchJson = ( url = "/", options = { method: 'GET' } ) =>
	Fetch( url, options )
		.then( response => response.json() );