import React, { Children, cloneElement, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles, createStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { EditController } from 'ra-core';
import { TitleForRecord } from 'ra-ui-materialui';

export const styles = createStyles( {
	root:
	{
		display: 'flex',
		marginTop: 15,
	},
} );

const sanitizeRestProps = ( {
	children,
	className,
	crudGetOne,
	crudUpdate,
	data,
	hasCreate,
	hasEdit,
	hasList,
	hasShow,
	id,
	isLoading,
	resetForm,
	resource,
	title,
	translate,
	version,
	match,
	location,
	history,
	options,
	locale,
	permissions,
	undoable,
	...rest
} ) => rest;

export const EditView = withStyles( styles )(
	( {
		basePath,
		children,
		classes,
		className,
		defaultTitle,
		hasList,
		hasShow,
		record,
		redirect,
		resource,
		save,
		title,
		undoable,
		version,
		hasCreate,
		...rest
	} ) =>
	{
		if( !children )
		{
			return null;
		}
		
		return (
			<Fragment>
				<div className={classnames( 'edit-page', classes.root, className )} {...sanitizeRestProps( rest )}>
					<TitleForRecord title={title} record={record} defaultTitle={defaultTitle} />
					{record ? (
						cloneElement( Children.only( children ), {
							basePath,
							record,
							redirect: `/${resource}/${record.id}`,
							resource,
							save,
							undoable,
							version,
						} )
					) : (
						<div>&nbsp;</div>
					)}
				</div>
			</Fragment>
		);
	}
);

EditView.propTypes =
{
	basePath: PropTypes.string,
	children: PropTypes.element,
	classes: PropTypes.object,
	className: PropTypes.string,
	defaultTitle: PropTypes.any,
	hasList: PropTypes.bool,
	hasShow: PropTypes.bool,
	record: PropTypes.object,
	redirect: PropTypes.oneOfType( [ PropTypes.string, PropTypes.bool ] ),
	resource: PropTypes.string,
	save: PropTypes.func,
	title: PropTypes.any,
	version: PropTypes.number,
};

EditView.defaultProps =
{
	classes: {},
};

const Edit = props => (
	<EditController {...props}>
		{controllerProps => <EditView {...props} {...controllerProps} />}
	</EditController>
);

Edit.propTypes =
{
	aside: PropTypes.node,
	children: PropTypes.node,
	classes: PropTypes.object,
	className: PropTypes.string,
	hasCreate: PropTypes.bool,
	hasEdit: PropTypes.bool,
	hasShow: PropTypes.bool,
	hasList: PropTypes.bool,
	id: PropTypes.any.isRequired,
	resource: PropTypes.string.isRequired,
	title: PropTypes.any,
};

export default Edit;
