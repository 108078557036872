import React, { Fragment } from 'react';
import { Typography } from '@material-ui/core';
import
{
	List,
	Datagrid,
	NumberField,
	SearchInput,
	TextInput,
	DateField,
	Filter,
	EditButton,
	ReferenceInput,
	AutocompleteInput,
	BulkDeleteButton,
} from 'react-admin';

import EntityLinkField from '../../Components/EntityLinkField';
import UserLinkField from '../Users/UserLinkField';

const VersionField = ({ record }) =>
{
	if( !record || !record.versions || record.versions.length === 0 )
	{
		return null;
	}

	const { version } = record.versions[ record.versions.length - 1 ];

	return ( <Typography variant="body2">{version}</Typography> );
};

const CommentField = ({ record }) =>
{
	if( !record || !record.versions || record.versions.length === 0 )
	{
		return null;
	}

	const { comment } = record.versions[ record.versions.length - 1 ];

	return ( <Typography variant="body2" color="textSecondary">{comment}</Typography> );
};

const ResourceFilter = ({ classes, ...props }) => (
    <Filter {...props} variant="outlined">
        <SearchInput source="q" alwaysOn />
        <TextInput source="Comment" />
        <TextInput source="Version" />
		<ReferenceInput label="Author" source="AuthorID" reference="admin/users">
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteButton {...props} undoable={false} />
    </Fragment>
);

const ResourceList = props => (
	<List {...props} sort={{ field: 'id', order: 'ASC' }} perPage={25} filters={<ResourceFilter />} bulkActionButtons={<PostBulkActionButtons />}>
		<Datagrid>
			<NumberField source="id" />
			<EntityLinkField source="name" />
			<CommentField label="Comment" />
			<VersionField label="Latest version" />
			<UserLinkField source="author" />
			<DateField source="created_at" options={{ year: 'numeric', month: 'long', day: 'numeric' }} />
			<EditButton />
		</Datagrid>
	</List>
);

export default ResourceList;