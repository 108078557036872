import React from 'react';
import {
	TextInput,
	ArrayInput,
} from 'react-admin';

import FileUploadInput from './FileUploadInput';
import TableFormIterator from '../../Components/Form/TableFormIterator';
import { Form, Section } from '../../Components/Form';
import { Edit } from '../../Components/Edit';

const DeviceEdit = ({ ...props }) => (
    <Edit {...props} undoable={false}>
        <Form variant="outlined" description={false}>
			<Section title="Versions">
				<ArrayInput source="versions" label={false}>
					<TableFormIterator disableIndex>
						<TextInput label="Version" source="version" variant="outlined" />
						<TextInput label="Comment" source="comment" variant="outlined" />
						<FileUploadInput label="File" source="file" />
					</TableFormIterator>
				</ArrayInput>
			</Section>
		</Form>
    </Edit>
);

export default DeviceEdit;