import OrganizationIcon from '@material-ui/icons/People'
import OrganizationList from   './OrganizationList';
import OrganizationCreate from './OrganizationCreate';
import OrganizationEdit from   './OrganizationEdit';

export default {
    list   : OrganizationList,
    create : OrganizationCreate,
    edit   : OrganizationEdit,
    icon   : OrganizationIcon,
};