import React from 'react';
import { Edit } from '../../Components/Edit';
import { Form } from '../../Components/Form';
import PackagesInput from './PackagesInput';

const EntityEdit = ({ ...props }) => (
	<Edit {...props} undoable={false}>
		<Form category="admin/products/categories" status thumbnail>
			<PackagesInput source="resource" />
		</Form>
	</Edit>
);

export default EntityEdit;