import React from 'react';
import {
    Create,
    TextInput,
	SimpleForm,
} from 'react-admin';

import { passwordValidator, passwordMatchValidator } from '../../Validators/PasswordValidator';

const OrganizationCreate = ({ ...props }) => (
	<Create {...props}>
		<SimpleForm variant="outlined">
			<TextInput source="name" />
			<TextInput source="domain" />
			<TextInput source="password" type="password" validate={passwordValidator} />
			<TextInput source="configrm_password" type="password" validate={passwordMatchValidator} />
		</SimpleForm>
	</Create>
);

export default OrganizationCreate;