import React from 'react';
import Label from '../../Components/Label';

const RoleField = ({ record, source }) =>
{
	if( source )
	{
		record = record[ source ];
	}

    return ( record && <Label key={record.id} color={record.color} text={record.name} /> );
};

export default RoleField;