import TariffIcon from '@material-ui/icons/VpnKey'
import TariffList from   './TariffList';
import TariffEdit from   './TariffEdit';
import TariffCreate from './TariffCreate';

export default {
    list   : TariffList,
    edit   : TariffEdit,
    create : TariffCreate,
    icon   : TariffIcon,
};