import React, { Children } from 'react';
import { Section } from '.';
import { withStyles } from '@material-ui/styles';
import classnames from 'classnames';
import {
    ReferenceInput,
    SelectInput,
	TextInput,
} from 'react-admin';
import { Grid, Typography } from '@material-ui/core';


const styles =
{
	root:
	{
		width: "100%",
		height: 42,
		maxHeight: 42,
	},
	column:
	{
	},
};

const statusChoices =
[
	{ id: 'new',       name: 'New', disabled: true },
	{ id: 'draft',     name: 'Draft' },
	{ id: 'pending',   name: 'Pending' },
	{ id: 'published', name: 'Published' },
	{ id: 'trash',     name: 'Trash' },
];

const Field = withStyles( styles )( ( { classes, children, title, ...props } ) => (
	<Grid container spacing={0} className={classnames(classes.root, "Category")}>
		<Grid item xs={4} className={classes.column}>
			<Typography variant="body1">{title}</Typography>
		</Grid>
		{Children.map( children, ( item )=> ( <Grid item xs={8} className={classes.column}>{item}</Grid> ) )}
	</Grid>
) );

const PublishSection = ( { toolbar, category, status, ...props } ) => (
	<Section title="Publish" toolbar={toolbar}>
		{category && (
			<Field title="Category">
				<ReferenceInput label={false} source="category.id" reference={category} variant="standard">
					<SelectInput source="name" style={{ margin: 0, marginTop: -1, width: "100%" }} />
				</ReferenceInput>
			</Field>
		)}
		{status && (
			<Field title="Status">
				<SelectInput source="status" choices={statusChoices} label={false} variant="standard" style={{ margin: 0, marginTop: -1, width: "100%" }} />
			</Field>
		)}
		<Field title="Updated At">
			<TextInput source="updated_at" label={false} variant="standard" disabled style={{ margin: 0, marginTop: -1, width: "100%" }} />
		</Field>
		<Field title="Created At">
			<TextInput source="created_at" label={false} variant="standard" disabled style={{ margin: 0, marginTop: -1, width: "100%" }} />
		</Field>
	</Section>
);

export default PublishSection;