export const ON_IDENTITY_STATUS_CHANGED  = 'OnIdentityStatusChanged';

export const OnIdentityStatusChanged = ( id, status ) => (
	{
		type: ON_IDENTITY_STATUS_CHANGED,
		payload: { id, status },
	}
);

export const IDENTITY_STATUS_OFFLINE = 0;
export const IDENTITY_STATUS_ONLINE  = 1;
export const IDENTITY_STATUS_AWAY    = 2;
export const IDENTITY_STATUS_DND     = 3;
