import React, { Fragment, useState } from 'react';
import { ButtonGroup, Button, MenuItem, TextField, makeStyles, CircularProgress } from '@material-ui/core';
import BalanceDialog from './BalanceDialog';
import { FetchJson } from "../../Services";

const useStyles = makeStyles( ( theme ) => (
{
	wrapper:
	{
		position: 'relative',
	},
	fabProgress:
	{
		position: 'absolute',
		top: 5,
		left: "73%",
		zIndex: 1,
	},
} ) );

const StatusInput = ( { onChangeStatus, account, ...props } ) =>
{
	const classes = useStyles();
	const [ state, setState ] = useState( account.status );
	const [ loading, setLoading ] = useState( false );

	const handleChange = ( event ) =>
	{
		setState( event.target.value );
		setLoading( true );

		FetchJson( `/admin/organizations/account/${props.record.id}/`,
				{
					method: 'PATCH',
					body: JSON.stringify( { id: account.id, status: event.target.value } ),
				}
			)
			.then( ( json ) =>
			{
				if( onChangeStatus )
				{
					onChangeStatus( json.status );
				}

				setState( json.status );
				setLoading( false );
			}
		);
	};

	return (
		<div className={classes.wrapper}>
			{loading && <CircularProgress color="primary" className={classes.fabProgress} size={30} />}
			<TextField {...props} label="Status" value={state} onChange={handleChange} style={{ width: 128 }} select disabled={loading}>
				<MenuItem value="active">Active</MenuItem>
				<MenuItem value="disabled">Disabled</MenuItem>
				<MenuItem value="locked" disabled>Locked</MenuItem>
			</TextField>
		</div>
	);
}

const BalanceInput = ( { style = { color: "black" }, onAdd, account, ...props } ) =>
{
	const [ dialog, setDialog ] = useState( { open: false, type: null } );

	const value = parseFloat( account.balance );

	const handleClickOpen = ( type ) =>
	{
		setDialog( { open: true, type } );
	};

	const handleClose = ( data ) =>
	{
		setDialog( { open: false, type: dialog.type } );

		data && onAdd && onAdd( data );
	};

	return (
		<Fragment>
			<ButtonGroup size="small">
				{account.status && account.status !== "pending_activation" ? <StatusInput account={account} {...props} /> : <Button disabled>Pending activation</Button>}
				<Button disabled style={{ color: value < 0 ? "red" : "black" }}>{value.toLocaleString( "ru-RU", { style: 'currency', currency: 'RUB' } ) }</Button>
				<Button color="secondary" onClick={() => handleClickOpen( "add" )}>Add</Button>
				<Button color="primary" onClick={() => handleClickOpen( "take" )}>Take</Button>
			</ButtonGroup>
			<BalanceDialog open={dialog.open} type={dialog.type} onClose={handleClose} {...props} />
		</Fragment>
	);
};

export default BalanceInput;