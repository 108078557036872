import React, { useState, useEffect, Children } from 'react';
import { Grid as MuiGrid } from '@material-ui/core';
import { withStyles, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import
{
	ArrayField,
	Datagrid,
	TextField,
	FunctionField,
	useVersion,
} from 'react-admin';
import { FormInput } from 'ra-ui-materialui';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Clear';
import UpdateIcon from '@material-ui/icons/ExpandLess';
import DowngradeIcon from '@material-ui/icons/ExpandMore';
import ErrorIcon from '@material-ui/icons/ErrorOutline';

import { green as ColorGreen, red as ColorRed } from '@material-ui/core/colors';
import { FetchJson } from "../../Services";

const PackageStatusField = ( { record, ...props } ) => (
	record.status === "added"      ? <AddIcon       style={{ fontSize: 18, verticalAlign: "top", color: ColorGreen[ 500 ] }} {...props} /> :
	record.status === "deleted"    ? <RemoveIcon    style={{ fontSize: 18, verticalAlign: "top", color: ColorRed[ 500 ] }}   {...props} /> :
	record.status === "updated"    ? <UpdateIcon    style={{ fontSize: 18, verticalAlign: "top", color: ColorGreen[ 500 ] }} {...props} /> :
	record.status === "downgraded" ? <DowngradeIcon style={{ fontSize: 18, verticalAlign: "top", color: ColorRed[ 500 ] }}   {...props} /> :
	record.status === "error"      ? <ErrorIcon     style={{ fontSize: 18, verticalAlign: "top", color: ColorRed[ 500 ] }}   {...props} /> :
	null
);

const GridItem = ( { label, value, record, render, basePath, resource, ...props } ) => (
	<FormInput
		basePath={basePath}
		input={<FunctionField label={label} render={render} />}
		record={record}
		resource={resource}
		variant="outlined"
	/>
);

const Grid = ( { children, ...props } ) => (
	<MuiGrid container spacing={0}>
		{Children.map( children, item => ( <MuiGrid item xs={12 / children.length} {...props}>{item}</MuiGrid> ) )}
	</MuiGrid>
);

const styles =
{
	root:
	{
		width: "100%",
	},
	panel:
	{
		boxShadow: "none",
	}
};

const TelemetryPackagesSection = ( { classes, ...props } ) =>
{
	const version = useVersion();
	const [ state, setState ] = useState( {} );

	const fetchData = () =>
	{
		FetchJson( `/admin/devices/${props.record.id}/telemetry/` )
			.then( data => setState( data ) );
	};

	useEffect( fetchData, [ version ] ); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className={classes.root}>
			<ExpansionPanel className={classes.panel}>
				<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
					<div style={{ width: '100%' }}>
						<Grid>
							<GridItem label="REPORT DATE"    render={record => state.created_at} />
							<GridItem label="PACKAGES COUNT" render={record => state.packages && `${state.packages.length} (${state.packages.filter( x => x.status !== "deleted" ).length} installed)` } />
							<GridItem label="ADDED"          render={record => state.packages && state.packages.filter( x => x.status === "added" ).length} />
							<GridItem label="DELETED"        render={record => state.packages && state.packages.filter( x => x.status === "deleted" ).length} />
						</Grid>
					</div>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails>
					<ArrayField source="packages" label={false} fullWidth record={state}>
						<Datagrid>
							<PackageStatusField />
							<TextField source="name" />
							<TextField source="version" />
						</Datagrid>
					</ArrayField>
				</ExpansionPanelDetails>
			</ExpansionPanel>
		</div>
	);
};

export default withStyles( styles )( TelemetryPackagesSection );
