import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

const styles =
{
	root: { width: 25, maxWidth: 25, maxHeight: 25 },
};

const ThumbnailField = withStyles( styles )( ( { classes, record: { thumbnail } } ) => (
	thumbnail ? <img src={thumbnail.absolute_url} className={classes.root} alt="" /> : null
) );

export default ThumbnailField;