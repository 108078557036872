import React, { useEffect, useCallback } from 'react';
import { withStyles } from '@material-ui/styles';
import { Card, CardContent, Typography } from '@material-ui/core';
import { useVersion } from 'react-admin';
import { FetchJson } from "../Services/DataProvider";

const styles =
{
	root:
	{
	},
	
	header:
	{
	},

	title:
	{
		padding: '8px 10px',
		borderBottom: '1px solid #f4f4f4',
	},

	body:
	{
	},

	footer:
	{
	},
};

const Box = ( { classes, children, title = '', url = null, onLoad } ) =>
{
	const version = useVersion();

	const fetchDashboard = useCallback(
		() =>
		{
			if( url )
			{
				FetchJson( url )
					.then( data => onLoad && onLoad( data ) );
			}
		} , [ onLoad, url ]
	);

	useEffect( () =>
		{
			fetchDashboard();
		},
		[ version, fetchDashboard ]
	); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Card className={classes.root}>
			<Typography variant="body1" component="div" className={classes.title}>
				{title}
			</Typography>
			<CardContent>
				<Typography variant="body2" component="div" className={classes.body}>
				{children}
				</Typography>
			</CardContent>
		</Card>
	);
};

export default withStyles( styles )( Box );