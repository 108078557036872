import React from 'react';
import
{
	Datagrid,
	DateField,
	EditButton,
	List,
	NumberField,
	Filter,
	SearchInput,
} from 'react-admin';

import OrganizationLinkField from './OrganizationLinkField';
import BalanceField from './BalanceField';

const OrganizationFilter = ({ classes, ...props }) => (
    <Filter {...props} variant="outlined">
        <SearchInput source="q" alwaysOn />
    </Filter>
);

const OrganizationList = ( { classes, ...props } ) => (
	<List {...props} sort={{ field: 'id', order: 'ASC' }} perPage={25} filters={<OrganizationFilter />} undoable="false">
		<Datagrid>
			<NumberField source="id" />
			<OrganizationLinkField />
			<BalanceField source="account.balance" label="Account Balance" options={{ style: 'currency', currency: 'RUB' }} />
			<DateField source="created_at" type="date" showTime />
			<EditButton />
		</Datagrid>
	</List>
);

export default OrganizationList;