import { useCallback } from 'react';

import useAuthProvider, { defaultAuthParams } from './useAuthProvider';
import { useLocation, useHistory } from 'react-router-dom';

function isUrl( string )
{
    let url;

    try
    {
        url = new URL( string );
    }
    catch( _ )
    {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

const useLogin = () =>
{
    const authProvider = useAuthProvider();
    const location = useLocation();
    const locationState = location.state;
    const history = useHistory();
    const nextPathName = locationState && locationState.nextPathname;

    const login = useCallback(
        ( params = {}, pathName = defaultAuthParams.afterLoginUrl ) =>
            authProvider.login( params ).then( ret =>
            {
                if( isUrl( nextPathName || pathName ) )
                {
                    window.location = nextPathName || pathName;
                }
                else
                {
                    history.push( nextPathName || pathName );
                }

                return ret;
            } ),
        [ authProvider, history, nextPathName ]
    );

    const loginWithoutProvider = useCallback(
        ( _, __ ) =>
        {
            history.push( defaultAuthParams.afterLoginUrl );
            return Promise.resolve();
        },
        [ history ]
    );

    return authProvider ? login : loginWithoutProvider;
};

export default useLogin;
