import React, { useState, useEffect, cloneElement } from 'react';
import { useVersion } from 'react-admin';

import * as Icons from "@material-ui/icons";

import {
	Menu,
	SubMenu,
	DashboardMenuItem,
	MenuItemLink,
	MenuItemHeader
} from '../Components/Menu';

import { FetchJson } from "../Services";

const MainMenu = () => 
{
	const version = useVersion();
	const [ state, setState ] = useState( [] );

	const fetchMenu = () =>
	{
		FetchJson( "/admin/menu/main" )
			.then( data => setState( data ) );
	};

	useEffect( fetchMenu, [ version ] ); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Menu>
			<DashboardMenuItem />

			{state && state.map(
				( item ) =>
				{
					const icon = item.icon && Icons[ item.icon ] && cloneElement( Icons[ item.icon ] );

					if( item.items && item.items.length > 0 )
					{
						return (
							<SubMenu primaryText={item.title} leftIcon={icon} key={item.id}>
								{item.items.map( i => ( <MenuItemLink to={i.url} primaryText={i.title} key={i.id} /> ) )}
							</SubMenu>
						);
					}

					if( !item.url )
					{
						return ( <MenuItemHeader primaryText={item.title} key={item.id} /> );
					}

					return ( <MenuItemLink primaryText={item.title} to={item.url} leftIcon={icon} key={item.id} /> )
				}
			)}

		</Menu>
	);
}

export default MainMenu;