import React from 'react';
import
{
	Datagrid,
	DateField,
	EditButton,
	List,
	TextField,
	NumberField,
	SearchInput,
    Filter,
} from 'react-admin';

import EntityLinkField from '../../Components/EntityLinkField';

const TariffFilter = ({ classes, ...props }) => (
    <Filter {...props} variant="outlined">
        <SearchInput source="q" alwaysOn />
    </Filter>
);

const TariffList = ( { classes, ...props } ) => (
	<List {...props} sort={{ field: 'id', order: 'ASC' }} perPage={25} filters={<TariffFilter />} undoable="false">
		<Datagrid>
			<NumberField source="id" />
			<EntityLinkField source="name" />
			<TextField source="slug" />
			<NumberField source="price" />
			<DateField source="created_at" type="date" showTime />
			<EditButton />
		</Datagrid>
	</List>
);

export default TariffList;