import React from 'react';
import { Link } from 'react-admin';

import UserNameField from './UserNameField';

const UserLinkField = ( { record, size, source } ) =>
{
	if( source && source !== "id" )
	{
		record = record[ source ];
	}

	return (
		record ? <Link to={`/admin/users/${record.id}`}>
			<UserNameField record={record} size={size} />
		</Link> : null
	);
}

UserLinkField.defaultProps =
{
    source: 'id',
    addLabel: true,
};

export default UserLinkField;