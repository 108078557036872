import React, { useEffect, Children, cloneElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, makeStyles, useMediaQuery, Typography } from '@material-ui/core';
import lodashGet from 'lodash/get';
import { setSidebarVisibility } from 'ra-core';
import classNames from 'classnames';
import Toolbar from '@material-ui/core/Toolbar';
import Logo from './Logo';

export const DRAWER_WIDTH = 260;
export const CLOSED_DRAWER_WIDTH = 50;

const useStyles = makeStyles( theme => (
	{
		root:
		{
			background: "#222d32",
			height: "100vh",
			display: "table-cell",
			flex: "unset",
			verticalAlign: "top",
		},
		toolbar:
		{
			height: 68,
			background: "#2c3b41",
			color: "#fff",
			padding: "12px 5px 12px 20px",
		},
		logo:
		{
			marginRight: 8,
		},
		logoTitle:
		{
			marginLeft: 4,
		},
		drawerPaper:
		{
			position: 'relative',
			height: 'auto',
			overflowX: 'hidden',
			width: props =>
				props.open
					? lodashGet( theme, 'sidebar.width', DRAWER_WIDTH )
					: lodashGet( theme, 'sidebar.closedWidth', CLOSED_DRAWER_WIDTH ),
			transition: theme.transitions.create( 'width',
				{
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.leavingScreen,
				}
			),
			backgroundColor: 'transparent',
			borderRight: 'none',
			[ theme.breakpoints.only( 'xs' ) ]:
			{
				marginTop: 0,
				height: '100vh',
				position: 'inherit',
				backgroundColor: theme.palette.background.default,
			},
			[ theme.breakpoints.up( 'md' ) ]:
			{
				border: 'none',
			},
			zIndex: 'inherit',
		},
	} ),
	{ name: 'RaSidebar' }
);

const Sidebar = ( {
	children,
	closedSize,
	size,
	classes: classesOverride,
	...rest
} ) =>
{
	const dispatch  = useDispatch();
	const isXSmall  = useMediaQuery( theme => theme.breakpoints.down( 'xs' ) );
	const isSmall   = useMediaQuery( theme => theme.breakpoints.down( 'sm' ) );
	const isDesktop = useMediaQuery( theme => theme.breakpoints.up( 'md' ) );
	
	useEffect( () =>
	{
		if( isDesktop )
		{
			dispatch( setSidebarVisibility( true ) );
		}
	}, [ isDesktop, dispatch ] );
	
	const open = useSelector( state => state.admin.ui.sidebarOpen );

	useSelector( state => state.locale ); // force redraw on locale change

	const handleClose   = () => dispatch( setSidebarVisibility( false ) );
	const toggleSidebar = () => dispatch( setSidebarVisibility( !open ) );

	const classes = useStyles( { classes: classesOverride, open } );

	return (
		<div className={classNames( classes.root )} {...rest}>
			{isXSmall ? (
				<Drawer
					variant="temporary"
					open={open}
					PaperProps={{ className: classes.drawerPaper }}
					onClose={toggleSidebar}
					{...rest}
				>
					{cloneElement( Children.only( children ), { onMenuClick: handleClose } )}
				</Drawer>
			) : isSmall ? (
				<Drawer
					variant="permanent"
					open={open}
					PaperProps={{ className: classes.drawerPaper }}
					onClose={toggleSidebar}
					{...rest}
				>
					{cloneElement( Children.only( children ), { onMenuClick: handleClose } )}
				</Drawer>
			) : (
				<Drawer
					variant="permanent"
					open={open}
					PaperProps={{ className: classes.drawerPaper }}
					onClose={toggleSidebar}
					{...rest}
				>
					{open && (
					<Toolbar disableGutters variant={isXSmall ? 'regular' : 'dense'} className={classes.toolbar}>
						<Logo className={classes.logo} size={20} />
						<Typography className={classes.logoTitle} variant="body1" color="inherit">Videomix</Typography>
					</Toolbar> )}
					{children}
				</Drawer>
			)}
        </div>
	);
};

export default Sidebar;
