import { useCallback } from "react";
import { w3cwebsocket } from "websocket";
import { WebSocket } from "./WebSocket";

const socket = new WebSocket();

let onConnectedHandlers = [];

socket.OnConnected = () =>
{
	onConnectedHandlers.forEach( x => x() );
	onConnectedHandlers = [];
};

const useCheckConnection = () =>
{
	return useCallback( () =>
		new Promise(
			( resolve, reject ) =>
			{
				switch( socket.GetReadyState() )
				{
					case w3cwebsocket.OPEN:
					{
						return resolve();
					}
					case w3cwebsocket.CONNECTING:
					default:
					{
						onConnectedHandlers.push( resolve );

						break;
					}
				}
			}
		),
		[]
	);
};

const useWebSocket = () =>
{
	const checkConnection = useCheckConnection();

	return {
		connect: () => socket.Open(),

		emit: useCallback(
			( method, ...args ) => checkConnection()
				.then( () => socket.Emit( method, ...args ) )
			,
			[ checkConnection ]
		),
		addEventListener: useCallback(
			( eventName, attachedTo, callback, thisCall ) => checkConnection()
				.then( () => socket.AddEventListener( eventName, attachedTo, callback, thisCall ) )
			,
			[ checkConnection ]
		),
		removeEventListener: useCallback(
			( eventName, attachedTo ) => checkConnection()
				.then( () => socket.RemoveEventListener( eventName, attachedTo ) )
			,
			[ checkConnection ]
		),
	};
};

export { useWebSocket };
