import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { useVersion } from 'react-admin';
import { toggleSidebar } from 'ra-core';

import classNames from 'classnames';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import UserMenu from './UserMenu';
import { FetchJson } from "../Services";

const useStyles = makeStyles( theme => (
	{
		root:
		{
			color: "rgb( 85, 85, 85 )"
		},
		toolbar:
		{
			padding: 10,
		},
		menuButton:
		{
			marginLeft: '0.1em',
			marginRight: '0.1em',
		},
		menuButtonIconClosed:
		{
			transition: theme.transitions.create( [ 'transform' ],
				{
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.leavingScreen,
				}
			),
			transform: 'rotate( 0deg )',
		},
		menuButtonIconOpen:
		{
			transition: theme.transitions.create( [ 'transform' ],
				{
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.leavingScreen,
				}
			),
			transform: 'rotate( 180deg )',
		},
		title:
		{
			flex: 1,
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
		},
	}
), { name: 'RaAppBar' } );

const AppBar = (
	{
		children,
		classes: classesOverride,
		className,
		logo,
		logout,
		open,
		title,
		...rest
	} ) =>
{
    const classes = useStyles( { classes: classesOverride } );
    const dispatch = useDispatch();
    const isXSmall = useMediaQuery( theme => theme.breakpoints.down( 'xs' ) );

	const version = useVersion();
	const [ user, setUser ] = useState( null );

	const fetchData = () =>
	{
		FetchJson()
			.then( user => setUser( user ) );
	};

	useEffect( fetchData, [ version ] ); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={classNames( className, classes.root )} {...rest}>
            <Toolbar disableGutters variant={isXSmall ? 'regular' : 'dense'} className={classes.toolbar}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => dispatch( toggleSidebar() )}
                    className={classNames( classes.menuButton )}
                >
                    <MenuIcon classes={{ root: open ? classes.menuButtonIconOpen : classes.menuButtonIconClosed }} />
                </IconButton>
				<Typography variant="subtitle1" color="inherit" className={classes.title} id="react-admin-title" />
                <UserMenu user={user} classes={classes} {...rest} />
            </Toolbar>
        </div>
    );
};

export default AppBar;
