import React, { useState }  from 'react';
import get from 'lodash/get';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import {
	Pagination,
	ReferenceManyField,
	Datagrid,
	TextField,
} from 'react-admin';

export const PackageSize = ( bytes ) =>
{
	if( bytes === 0 )
	{
		return '0 Bytes';
	}

    const k = 1024;
    const sizes = [ 'Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB' ];

    const i = Math.floor( Math.log( bytes ) / Math.log( k ) );

    return parseFloat( ( bytes / Math.pow( k, i ) ).toFixed( 2 ) ) + ' ' + sizes[ i ];
}

export const PackageSizeField = ( props ) =>
{
	const bytes = parseInt( get( props.record, props.source || "size" ) );

	if( typeof( bytes ) === "undefined" || !bytes )
	{
		return "";
	}

	return PackageSize( bytes );
}

const PackagesVersionsExpand = ( { setError, onClose, ...props } ) =>
{
	const rowClick = ( id, basePath, record ) =>
	{
		onClose( record );

		return false;
	};

	return (
		<ReferenceManyField {...props}
			basePath="admin/products"
			target="package"
			source="package"
			reference="admin/packages/versions"
			pagination={<Pagination />}
			perPage={15}
			addLabel={false}
			sortable={false}
			fullWidth
		>
			<Datagrid rowClick={rowClick}>
				<TextField source="filename" label={false} />
				<TextField source="version" label={false} />
				<PackageSizeField source="size" label={false} />
			</Datagrid>
		</ReferenceManyField>
	);
};

const PackagesDialog = ( { open, onClose, ...props } ) =>
{
	const [ error, setError ] = useState( null );

    return (
		<Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
            <DialogTitle>Select package</DialogTitle>
			<DialogContent>
				{error && <DialogContentText color="primary">Error: {error}</DialogContentText>}

				<ReferenceManyField
					reference="admin/packages"
					addLabel={false}
					sortable={false}
					pagination={<Pagination />}
					perPage={15}
					basePath="admin/products"
					fullWidth
				>
					<Datagrid rowClick="expand" expand={<PackagesVersionsExpand {...{onClose, setError}} />}>
						<TextField source="package" />
						<TextField source="version" label="Latest version" />
						<PackageSizeField source="size" label="Size (without deps)" />
					</Datagrid>
				</ReferenceManyField>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary">Cancel</Button>
			</DialogActions>
		</Dialog>
    );
};

export default PackagesDialog;