import React from 'react';

const FileLinkField = ( { record, source } ) =>
{
	if( source )
	{
		record = record[ source ];
	}

	return ( record && <a href={record.absolute_url} target="_blank" rel="noopener noreferrer">{record.name}</a> );
}

FileLinkField.defaultProps =
{
    source: null,
    addLabel: true,
};

export default FileLinkField;