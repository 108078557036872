import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { CircularProgress } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { addField } from 'ra-core';
import FileLinkField from './FileLinkField';
import * as Cookie from "../../Cookie";
import { AUTH_COOKIE_NAME } from "../../Auth/AuthProvider";

const styles = theme => createStyles(
	{
		dropZone:
		{
			background: theme.palette.background.default,
			cursor: 'pointer',
			paddingTop: '1rem',
			paddingBottom: '1rem',
			textAlign: 'center',
			color: theme.palette.text.hint,
			minWidth: "200px",
		},
	}
);

class FileUploadInput extends Component
{
	static propTypes =
	{
        children: PropTypes.element,
        classes: PropTypes.object,
        input: PropTypes.object,
        options: PropTypes.object,
        resource: PropTypes.string,
        source: PropTypes.string,
		url: PropTypes.string,
    };

	static defaultProps =
	{
		url: "/admin/files/upload",
    };

	constructor( props )
	{
		super( props );

		const file = props.input.value;

		this.state = { uploadProgress: { state: null, value: 0 }, file };
	}

	componentWillReceiveProps( nextProps )
	{
		const file = nextProps.input.value;

		this.setState( { file } );
	}

	fileUploadProgress( event )
	{
		if( event.lengthComputable )
		{
			const uploadProgress =
			{
				state: "pending",
				value: ( event.loaded / event.total ) * 100
			};

			this.setState( { uploadProgress } );
		}
	}

	fileUploadSuccess( event, request )
	{
		if( request.status !== 200 )
		{
			this.fileUploadError( event, request );

			return;
		}

		const file = JSON.parse( request.response );

		this.props.input.onChange( file );

		this.setState( { uploadProgress: { state: "done", value: 100 }, file } );
	}

	fileUploadError( event, request )
	{
		const uploadProgress = { state: "error", value: 0 };

		console.log( request.statusText );

		this.setState( { uploadProgress, error: request.statusText } );
	}

	sendFile( file )
	{
		const req = new XMLHttpRequest();

		req.upload.addEventListener( "progress", ( e ) => { this.fileUploadProgress( e, req ); } );
		req.addEventListener( "load", ( e ) => { this.fileUploadSuccess ( e, req ); } );

		const formData = new FormData();

		formData.append( "file", file, file.name );

		req.open( "POST", process.env.PUBLIC_URL + "/api" + this.props.url );
		req.setRequestHeader( "Authorization", 'Bearer ' + Cookie.Get( AUTH_COOKIE_NAME ) );
		req.send( formData );
	}

	onDrop = ( acceptedFiles ) =>
	{
        this.sendFile( acceptedFiles.pop() );
    }

	renderFile()
	{
		const { file } = this.state;

		return ( <FileLinkField name="lat" component="input" record={file} /> );
	}

	renderDropzone()
	{
		const { classes = {} } = this.props;
		const { error } = this.state;

		return (
			<Dropzone onDrop={this.onDrop} multiple={false}>
				{( { getRootProps, getInputProps } ) => (
					<div {...getRootProps()} className={classes.dropZone}>
						<input {...getInputProps()} />
						{error ? ( <div>Error: {error}</div> ) : <div>Drag 'n' drop some files here, or click to select files</div>}
					</div>
				)}
			</Dropzone>
		);
	}

	renderProgress()
	{
		const { uploadProgress } = this.state;
		const { value } = uploadProgress;

		return ( <CircularProgress variant="static" value={value} /> );
	}

	render()
	{
		const { uploadProgress, file } = this.state;

		if( uploadProgress.state && uploadProgress.state !== "done" && uploadProgress.state !== "error" )
		{
			return this.renderProgress();
		}

		return file ? this.renderFile() : this.renderDropzone();
	}
}

export default compose( addField, withStyles( styles ) )( FileUploadInput );