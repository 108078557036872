import React from 'react';
import SmallBox from './SmallBox';

import organizations from '../Entity/Organizations';

const OrganizationsCountBox = ( { data } ) => (
	<SmallBox
		value={data.organizations || 0}
		title="Organizations"
		icon={<organizations.icon />}
		color="00c0ef"
	/>
);

export default OrganizationsCountBox;