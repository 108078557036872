import React, { useState, Children, cloneElement, Fragment } from 'react';
import { Tabs as TabsUI, Tab as TabUI, Typography, AppBar, Paper, Box } from '@material-ui/core';
import { FormInput } from 'ra-ui-materialui';

export function TabPanel( props )
{
	const { basePath, record, resource, children, value, index, p = 0, ...other } = props;

	return (
		<Typography component="div" role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
			<Paper>
				<Box p={p}>
					{Children.map( children, input => (
						<FormInput
							basePath={basePath}
							input={input}
							record={record}
							resource={resource}
							variant="outlined"
						/>
					))}
				</Box>
			</Paper>
		</Typography>
	);
}

export function Tabs( props )
{
	const { children, ...other } = props;
	const [ value, setValue ] = useState( 0 );

	const handleChange = ( event, newValue ) =>
	{
		setValue( newValue );
	};

	return (
		<Fragment>
			<AppBar position="static">
				<TabsUI value={value} onChange={handleChange}>
					{Children.map( children, input => (<TabUI label={input.props.title} />) )}
				</TabsUI>
			</AppBar>
			{Children.map( children, ( input, i ) => cloneElement( input, { value, index: i, ...other } ) )}
		</Fragment>
	);
}
