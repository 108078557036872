import ResourceIcon   from '@material-ui/icons/LibraryBooks';
import ResourceList   from './ResourceList';
import ResourceEdit   from './ResourceEdit';
import ResourceCreate from './ResourceCreate';

export default {
    list: ResourceList,
    edit: ResourceEdit,
	create: ResourceCreate,
    icon: ResourceIcon,
};