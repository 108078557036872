import React, { Fragment, useState } from 'react';
import {
	Pagination,
	ArrayField,
	Datagrid,
	DateField,
	TextField,
	NumberField,
	useVersion
} from 'react-admin';
import { Typography, withStyles } from '@material-ui/core';
import BalanceInput from './BalanceInput';
import BalanceLogField from './BalanceLogField';
import { useEffect } from 'react';
import EntityLinkField from '../../Components/EntityLinkField';
import { FetchJson } from "../../Services";

const styles =
{
	label:
	{
		marginTop: 15,
		marginBottom: 7,
	},
};

const AccountSection = ( { classes, ...props } ) =>
{
	const version = useVersion();
	const [ state, setState ] = useState( {} );

	const fetchAccountData = () =>
	{
		FetchJson( `/admin/organizations/account/${props.record.id}/` )
			.then( data => setState( data ) );
	};

	useEffect( fetchAccountData, [ version ] ); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Fragment>
			<Typography className={classes.label} variant="button" component="p" color="textSecondary">Current value</Typography>
			<BalanceInput account={state} onAdd={() => fetchAccountData()} onChangeStatus={() => fetchAccountData()} {...props} />
			
			<Typography className={classes.label} variant="button" component="p" color="textSecondary">History</Typography>
			<ArrayField sort={{ field: 'id', order: 'DESC' }} pagination={<Pagination />} fullWidth record={state} source="log">
				<Datagrid>
					<NumberField fullWidth source="id" label="ID" />
					<EntityLinkField fullWidth target="responsible" basePath="/admin/users" label="Responsible" emptyText="N/A" />
					<BalanceLogField fullWidth source="amount" />
					<DateField fullWidth source="date" type="date" showTime />
					<TextField fullWidth source="description" />
				</Datagrid>
			</ArrayField>
		</Fragment>
	);
};

export default withStyles( styles )( AccountSection );