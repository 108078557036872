import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import MuiMenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import DefaultMenuItemIcon from '@material-ui/icons/TripOrigin';

const styles = theme => (
	{
		root:
		{
			//color: theme.palette.text.secondary,
			padding: "12px 5px 12px 15px",
			color: "#b8c7ce",
			fontSize: 15,
			height: "unset",
			borderLeft: "3px solid transparent",
		},
		active:
		{
			//color: theme.palette.text.primary,
			color: "#FFFFFF",
			background: "#1e282c",
			borderLeftColor: "#3c8dbc",
		},
		iconContainer:
		{
			minWidth: 35,
		},
		icon:
		{
			color: "#b8c7ce",
			fontSize: 20,
			minWidth: "unset"
		},
		icon2Container:
		{
			minWidth: "unset",
			position: "absolute",
			right: 10,
			top: "50%",
			marginTop: -10,
		},
		icon2:
		{
			color: "#b8c7ce",
			fontSize: 15,
			minWidth: "unset"
		},
	}
);

const MenuItem = (
{
	classes,
	className,
	primaryText,
	leftIcon,
	rightIcon,
	component = 'a',
	open,
	ref,
	...props
} ) =>
{
	const renderLeftIcon = () =>
	{
		return (
			<ListItemIcon className={classes.iconContainer}>
				{cloneElement( leftIcon || DefaultMenuItemIcon, { titleAccess: primaryText, className: classes.icon, ref: x => x } )}
			</ListItemIcon>
		);
	};

	const renderRightIcon = () =>
	{
		if( !rightIcon )
		{
			return null;
		}

		if( !open )
		{
			return null;
		}

		return (
			<ListItemIcon className={classes.icon2Container}>
				{cloneElement( rightIcon, { titleAccess: primaryText, className: classes.icon } )}
			</ListItemIcon>
		);
	};

	const renderPrimaryText = () =>
	{
		if( !open )
		{
			return null;
		}

		return primaryText;
	};

	const renderMenuItem = () =>
	{
		return (
			<MuiMenuItem
				className={classnames( classes.root, className )}
				activeClassName={classes.active}
				component={component}
				disableGutters={true}
				ref={ref}
				{...props}
			>
				{renderLeftIcon()}
				{renderPrimaryText()}
				{renderRightIcon()}
			</MuiMenuItem>
		);
	};

	if( open )
	{
		return renderMenuItem();
	}

	return ( <Tooltip title={primaryText} placement="right">{renderMenuItem()}</Tooltip> );
}

MenuItem.propTypes =
{
	classes: PropTypes.object,
	className: PropTypes.string,
	leftIcon: PropTypes.element,
	rightIcon: PropTypes.element,
	onClick: PropTypes.func,
	primaryText: PropTypes.node,
	staticContext: PropTypes.object,
};

export default withStyles( styles )( MenuItem );