import React from 'react';
import AvatarField from './AvatarField';
import pure from 'recompose/pure';

const UserNameField = ( { record = {}, size } ) => (
	<div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
		<AvatarField record={record} size={size} />
		&nbsp;{record.name}
	</div>
);

const PureUserNameField = pure( UserNameField );

PureUserNameField.defaultProps =
{
	source: 'name',
	label: 'resources.users.fields.name',
};

export default PureUserNameField;