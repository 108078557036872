import React from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { Title } from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import compose from 'recompose/compose';
import { changeTheme } from './Actions';

const styles =
{
	label: { width: '10em', display: 'inline-block' },
	button: { margin: '1em' },
};

const Configuration = ( {
	classes,
	theme,
	changeTheme,
} ) => (
	<Card>
		<Title title="Settings" />
		<CardContent>
			<div className={classes.label}>Theme</div>
			<Button
				variant={theme === 'light' ? 'contained' : 'outlined'}
				className={classes.button}
				color="primary"
				onClick={() => changeTheme( 'light' )}
			>
				Light
			</Button>
			<Button
				variant={theme === 'dark' ? 'contained' : 'outlined'}
				className={classes.button}
				color="primary"
				onClick={() => changeTheme( 'dark' )}
			>
				Dark
			</Button>
		</CardContent>
	</Card>
);

const mapStateToProps = state => ( {
	theme: state.theme,
} );

const enhance = compose(
	connect(
		mapStateToProps,
		{
			changeTheme,
		}
	),
	withStyles( styles )
);

export default enhance( Configuration );