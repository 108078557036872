import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { ListItemText, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const styles =
{
	root:
	{
		color: "#4b646f",
		background: "#1a2226",
		overflow: "hidden",
		textOverflow: "clip",
		padding: "10px 25px 10px 15px",
		fontSize: "12px",
	},
	primary:
	{
		color: "#4b646f",
	}
};

class MenuItemHeader extends Component
{
	static propTypes =
	{
		classes: PropTypes.object,
		className: PropTypes.string,
		leftIcon: PropTypes.element,
		primaryText: PropTypes.node,
		sidebarIsOpen: PropTypes.bool,
	};

	render()
	{
		const { open, primaryText, classes, className } = this.props;

		if( !open )
		{
			return null;
		}

		return (
			<Fragment>
				<ListItemText
					disableTypography
					primary={<Typography type="subheading" className={classnames(classes.primary, className)}>{primaryText}</Typography>}
					className={classnames(classes.root, className)}
				/>
			</Fragment>
		);
	}
}

export default withStyles( styles )( MenuItemHeader );