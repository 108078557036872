import React from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FormHelperText, FormControl } from '@material-ui/core';
import { useInput } from 'ra-core';
import { InputHelperText } from 'ra-ui-materialui';
import { makeStyles } from '@material-ui/core/styles';

const styles = theme => (
{
	root:
	{
		marginBottom: 20,
	},

	"@global": Object.assign( {}, 
	{
		'.ck-editor':
		{
			'& .ck-editor__editable':
			{
				minHeight: 300,
			}
		},
		'.ck.ck-reset':
		{
			wordWrap: 'anywhere',
		}
	}
) } );

const useStyles = makeStyles( styles );

const CKEditorInput = ( {
		record = {},
		toolbar = true,
		fullWidth = true,
		helperText = false,
		source,
		resource,
		variant,
		margin = 'dense',
		...rest
	} ) =>
{
	const classes = useStyles();

	const {
        input: { value, onChange },
        meta: { touched, error } 
    } = useInput( { source, ...rest } );

	return (
        <FormControl className={classes.root} error={!!( touched && error )} fullWidth={fullWidth} margin={margin}>
			<CKEditor
				editor={ClassicEditor}
				data={value}
				onInit={editor => {}}
				onChange={( event, editor ) => onChange( editor.getData() )}
				onBlur={( event, editor ) => {}}
				onFocus={( event, editor ) => {}}
			/>

            {helperText || ( touched && error ) ? (
                <FormHelperText error={!!error}>
                    <InputHelperText error={error} helperText={helperText} touched={touched} />
                </FormHelperText>
            ) : null}
        </FormControl>
    );
};

export default CKEditorInput;