import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TextInput } from 'react-admin';

const styles = theme => (
{
	root:
	{
		width: '100%',
		marginTop: 0,
	},
} );

const PostNameInput = ( { classes, ...props } ) => (
	<TextInput {...props} className={classes.root} />
);

export default withStyles( styles )( PostNameInput );