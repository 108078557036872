import React from 'react';
import {
    ReferenceInput,
    SelectInput,
    TextInput,
    DateInput,
	Pagination,
	ReferenceManyField,
	Datagrid,
	DateField,
	TextField,
	NumberField,
} from 'react-admin';

import RolesField from '../Users/RolesField';
import UserLinkField from '../Users/UserLinkField';

import EntityLinkField from '../../Components/EntityLinkField';
import { passwordValidator, passwordMatchValidator } from '../../Validators/PasswordValidator';
import DeviceShow from './DeviceShow';
import TariffOptionsField from './TariffOptionsField';
import { Edit } from '../../Components/Edit';
import { Form, Tabs, TabPanel } from '../../Components/Form';
import AccountSection from './AccountSection';

const OrganizationEdit = ( { ...props } ) => (
	<Edit {...props} undoable={false}>
		<Form description={false}>
			<Tabs>
				<TabPanel title="Account" p={2}>
					<AccountSection />
				</TabPanel>

				<TabPanel title="Tariff" p={2}>
					<ReferenceInput fullWidth label="Tariff" source="tariff.id" reference="admin/tariffs" variant="outlined">
						<SelectInput source="name" optionText={u => `#${u.id} - ${u.name} (${u.slug})`} />
					</ReferenceInput>
					<DateInput fullWidth source="tariff_next_debit" type="date" showTime />
					<TariffOptionsField source="tariff_values" />
				</TabPanel>

				<TabPanel title="Identity" p={2}>
					<TextInput fullWidth source="domain" />
					<TextInput fullWidth source="password" type="password" validate={passwordValidator} />
					<TextInput fullWidth source="confirm_password" type="password" validate={passwordMatchValidator} />
				</TabPanel>

				<TabPanel title="Members">
					<ReferenceManyField reference="admin/users" target="OrganizationID" sort={{ field: 'id', order: 'ASC' }} addLabel={false} pagination={<Pagination />} fullWidth>
						<Datagrid>
							<UserLinkField />
							<TextField source="email" />
							<RolesField label="Roles" />
							<DateField source="created_at" type="date" showTime />
						</Datagrid>
					</ReferenceManyField>
				</TabPanel>

				<TabPanel title="Devices">
					<ReferenceManyField reference="admin/devices" target="OrganizationID" sort={{ field: 'id', order: 'ASC' }} addLabel={false} pagination={<Pagination />} fullWidth>
						<Datagrid rowClick="expand" expand={<DeviceShow />}>
							<NumberField source="id" />
							<EntityLinkField basePath="/admin/devices" />
							<TextField source="cpu.model" label="" />
							<DateField source="created_at" label="REGISTERED ON" showTime />
						</Datagrid>
					</ReferenceManyField>
				</TabPanel>
			</Tabs>
		</Form>
	</Edit>
);

export default OrganizationEdit;
