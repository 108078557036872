import React from 'react';
import
{
	Datagrid,
	TextField,
	EditButton,
	List,
	NumberField,
} from 'react-admin';

import Label from '../../Components/Label';

const LabelColorField = ({ record }) =>
{
    return ( record && <Label key={record.id} color={record.color} text={`0x${record.color.toUpperCase()}`} /> );
};

const RoleList = ( { classes, ...props } ) => (
	<List {...props} sort={{ field: 'id', order: 'ASC' }} perPage={25} undoable="false">
		<Datagrid>
			<NumberField source="id" />
			<TextField source="name" />
			<LabelColorField label="Color" />
			<EditButton />
		</Datagrid>
	</List>
);

export default RoleList;