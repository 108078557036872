import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';

import products from '../Products';

const styles =
{
	icon: { paddingRight: '0.5em' },
	link:
	{
		display: 'inline-flex',
		alignItems: 'center',
	},
};

const LinkToRelatedProducts = ( { classes, record } ) => (
	<Button
		size="small"
		color="primary"
		component={Link}
		to={{
			pathname: '/admin/products',
			search: stringify(
				{
					page: 1,
					perPage: 25,
					sort: 'ID',
					order: 'DESC',
					filter: JSON.stringify( { CategoryID: record.id } ),
				}
			),
		}}
		className={classes.link}
	>
		<products.icon className={classes.icon} />
		Products
	</Button>
);

export default withStyles( styles )( LinkToRelatedProducts );