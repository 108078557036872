import React, { Children, cloneElement, Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import get from 'lodash/get';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/RemoveCircleOutline';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { translate } from 'ra-core';
import classNames from 'classnames';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { FormInput } from 'react-admin';

const styles = theme => (
{
	root:
	{
		padding: 0,
		marginBottom: 0,
	},
	index:
	{
		width: '3em',
		paddingTop: '1em',
		[theme.breakpoints.down( 'sm' )]: { display: 'none' },
	},
	form: { flex: 2 },
	action:
	{
		paddingTop: '0.5em',
	},
	leftIcon:
	{
		marginRight: theme.spacing.unit,
	},
} );

export class TableFormIterator extends Component
{
	constructor( props )
	{
		super( props );
		// we need a unique id for each field for a proper enter/exit animation
		// but redux-form doesn't provide one (cf https://github.com/erikras/redux-form/issues/2735)
		// so we keep an internal map between the field position and an autoincrement id
		this.nextId = props.fields.length
			? props.fields.length
			: props.defaultValue
				? props.defaultValue.length
				: 0;

		// We check whether we have a defaultValue (which must be an array) before checking
		// the fields prop which will always be empty for a new record.
		// Without it, our ids wouldn't match the default value and we would get key warnings
		// on the CssTransition element inside our render method
		this.ids = this.nextId > 0 ? Array.from( Array( this.nextId ).keys() ) : [];
	}

	removeField = index => () =>
	{
		const { fields } = this.props;
		this.ids.splice( index, 1 );
		fields.remove( index );
	};

	addField = () =>
	{
		const { fields } = this.props;
		this.ids.push( this.nextId++ );
		fields.push( {} );
	};

	render()
	{
		const {
			basePath,
			classes = {},
			children,
			fields,
			meta: { error, submitFailed },
			record,
			resource,
			source,
			variant,
			translate,
			disableAdd,
			disableRemove,
			disableIndex,
		} = this.props;
		
		const records = get( record, source );

		return fields ? (
			<span>
				{submitFailed && error && <span>{error}</span>}
				<Table className={classes.root} size="small">
					<TableHead>
						<TableRow>
							{!disableIndex && (
								<TableCell variant="head">Index</TableCell>
							)}
							{Children.map( children, ( input ) => (
								<TableCell variant="head">{input.props.label || input.props.source}</TableCell> 
							) )}
							{!disableRemove && (
								<TableCell variant="head">Action</TableCell>
							)}
						</TableRow>
					</TableHead>
					<TableBody>
						{fields.map( ( member, index ) => (
							<TableRow key={this.ids[ index ]}>
								{!disableIndex && (
									<TableCell>
										<Typography variant="button" className={classes.index}>{index + 1}</Typography>
									</TableCell>
								)}
								{Children.map( children, ( input, i ) => (
									<TableCell>
										<FormInput
											basePath={input.props.basePath || basePath}
											input={cloneElement( input,
												{
													source: input.props.source ? `${member}.${input.props.source}` : member,
													index: input.props.source ? undefined : index,
													label: input.props.label || input.props.source,
													addlabel: "false",
													variant: input.props.variant || variant,
												}
											)}
											record={( records && records[ index ] ) || {}}
											resource={resource}
										/>
									</TableCell>
								) )}
								{!disableRemove && (
									<TableCell>
										<span className={classes.action}>
											<Button
												className={classNames( 'button-remove', `button-remove-${source}-${index}` )}
												size="small"
												onClick={this.removeField( index )}
											>
												<CloseIcon className={classes.leftIcon} />
												{translate( 'ra.action.remove' )}
											</Button>
										</span>
									</TableCell>
								)}
							</TableRow>
						) )}
					</TableBody>
				</Table>
				{!disableAdd && (
					<span className={classes.action}>
						<Button
							className={classNames( 'button-add', `button-add-${source}` )}
							size="small"
							onClick={this.addField}
						>
							<AddIcon className={classes.leftIcon} />
							{translate( 'ra.action.add' )}
						</Button>
					</span>
				)}
			</span>
		) : null;
	}
}

TableFormIterator.defaultProps =
{
	disableAdd: false,
	disableRemove: false,
	disableIndex: false,
};

TableFormIterator.propTypes =
{
	defaultValue: PropTypes.any,
	basePath: PropTypes.string,
	children: PropTypes.node,
	classes: PropTypes.object,
	className: PropTypes.string,
	fields: PropTypes.object,
	meta: PropTypes.object,
	record: PropTypes.object,
	source: PropTypes.string,
	resource: PropTypes.string,
	translate: PropTypes.func,
	disableAdd: PropTypes.bool,
	disableRemove: PropTypes.bool,
	disableIndex: PropTypes.bool,
};

export default compose( translate, withStyles( styles ) )( TableFormIterator );