import React from 'react';
import {
    ReferenceInput,
    SelectInput,
    TextInput,
} from 'react-admin';

import { passwordValidator, passwordMatchValidator } from '../../Validators/PasswordValidator';
import RolesInput from './RolesInput';

import { Create } from '../../Components/Create';
import { Form, Section } from '../../Components/Form';

const UserCreate = ({ ...props }) => (
	<Create {...props} undoable="false">
		<Form>
			<Section title="Identity" sidebar>
				<ReferenceInput label="Organization" source="organization.id" reference="admin/organizations">
						<SelectInput source="name" />
				</ReferenceInput>
				<TextInput source="email" />
				<TextInput source="password" type="password" validate={passwordValidator} />
				<TextInput source="configrm_password" type="password" validate={passwordMatchValidator} />
				<RolesInput />
			</Section>
		</Form>
	</Create>
);

export default UserCreate;