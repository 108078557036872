import React from 'react';
import { useLogout } from 'react-admin';
import { LoadingIndicator } from 'react-admin';

import SettingsIcon from '@material-ui/icons/Settings';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import AvatarField from '../Entity/Users/AvatarField';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

const UserTitle     = ( { user } ) => ( <Typography variant="body1" color="inherit">{user.name}</Typography> );
const UserSubTitle  = ( { user } ) => ( <Typography variant="caption" color="inherit">{user.email}</Typography> );

const SettingsButton = ( { classes } ) => (
	<IconButton
        color="inherit"
        aria-label="settings"
        className={classes.menuButton}
    >
        <SettingsIcon />
    </IconButton>
);

const LogoutButton = ( { classes } ) =>
{
	const logout = useLogout();
	const handleClick = () => logout();

	return (
		<IconButton
			color="inherit"
			onClick={handleClick}
			aria-label="logout"
			className={classes.menuButton}
		>
			<LogoutIcon />
		</IconButton>
	);
};

const UserInfo = ( { user } ) => (
	<div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
		<AvatarField size={40} record={user} showOnline={false} />
		<div style={{ display: 'block', alignItems: 'center', paddingLeft: "5px" }}>
			<div>
				<UserTitle user={user} />
			</div>
			<div>
				<UserSubTitle user={user} />
			</div>
		</div>
	</div>
);

const UserMenu = ( { user, ...props } ) => ( user ?
	<div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
        <LoadingIndicator {...props} />
		<SettingsButton {...props} />
		<UserInfo user={user} />
		<LogoutButton {...props} />
	</div>
	: null
);

export default UserMenu;