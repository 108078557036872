import React from 'react';
import { connect } from 'react-redux';
import Label from '../../Components/Label';

export default connect( state => ( { websocket: state.websocket } ), undefined )( ( { record, websocket } ) =>
{
	const isOnline = websocket.identity.isOnline( record.id );
	const color    = isOnline ? "00A000" : "A00000";
	const text     = isOnline ? "ONLINE" : "OFFLINE";

	return <Label key={record.id} color={color} text={text} />;
} );