import React, { useState, Children, cloneElement, Fragment, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useVersion, Title } from 'react-admin';

import UsersCountBox from './UsersCountBox';
import ProductsCountBox from './ProductsCountBox';
import ResourcesCountBox from './ResourcesCountBox';
import OrganizationsCountBox from './OrganizationsCountBox';
import SystemInfoBox from './SystemInfoBox';
import { FetchJson } from "../Services";

const styles =
{
	root:
	{
		flexGrow: 1,
	},
	container:
	{
		marginTop: 10,
	},
};

const Dashboard = ( { classes, ...props } ) =>
{
	const version = useVersion();
	const [ state, setState ] = useState( {} );

	const items =
	[
		<UsersCountBox />,
		<ProductsCountBox />,
		<ResourcesCountBox />,
		<OrganizationsCountBox />,
		<SystemInfoBox />,
	];

	const fetchDashboard = () =>
	{
		FetchJson( '/admin' )
			.then( data => setState( data ) );
	};

	useEffect( fetchDashboard, [ version ] ); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Fragment>
			<Title title="Dashboard" />
			<div className={classes.root}>
				<Grid container spacing={3} className={classes.container}>
					{Children.map( items, item => (
						<Grid item xs={12} sm={12} md={6} lg={3}>
							{cloneElement( item, { data: state } )}
						</Grid>
					) )}
				</Grid>
			</div>
		</Fragment>
	);
}

export default withStyles( styles )( Dashboard );