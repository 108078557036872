import React from 'react';
import
{
	Datagrid,
	Filter,
	List,
	ReferenceInput,
	SearchInput,
	SelectInput,
	TextField,
	DateField,
	EditButton,
	FunctionField,
} from 'react-admin';

import EntityLinkField from '../../Components/EntityLinkField';
import UserLinkField from '../Users/UserLinkField';
import Label from '../../Components/Label';
import PostStatus from '../../Components/Post/PostStatus';
import { withStyles } from '@material-ui/core';

const LabelColorField = ( { record } ) =>
{
	const status = PostStatus.find( x => x.id === record.status || x.name === record.status );

    return ( status && <Label key={record.id} color={status.color} text={status.name} /> );
};

const thumbStyles =
{
	root:
	{
		margin: 0,
		height: "5rem",
		width: "8rem",
		background: "#f7f7f7 center no-repeat",
		backgroundSize: "cover",
	},
};

const ThumbnailField = withStyles( thumbStyles )( ( { classes, ...props } ) => (
	<FunctionField {...props} render={
		record => (
			<div className={classes.root} style={{ backgroundImage: `url( ${record.thumbnail.absolute_url} )` }} />
		)
	} />
) );

export const ProductFilter = props => (
	<Filter {...props} variant="outlined">
		<SearchInput source="q" alwaysOn />
		<ReferenceInput label="Category" source="CategoryID" reference="admin/products/categories" sort={{ field: 'ID', order: 'ASC' }} alwaysOn>
			<SelectInput source="name" resettable />
		</ReferenceInput>
		<SelectInput source="Status" choices={PostStatus} resettable alwaysOn />
	</Filter>
);

const EntityList = props => (
	<List {...props} filters={<ProductFilter />} perPage={25} sort={{ field: 'ID', order: 'ASC' }}>
		<Datagrid>
			<ThumbnailField label="Thumbnail" />
			<EntityLinkField source="name" />
			<TextField label="Category" source="category.name" />
			<UserLinkField source="author" />
			<LabelColorField label="Status" />
			<DateField source="created_at" />
			<DateField source="updated_at" />
			<EditButton />
		</Datagrid>
	</List>
);

export default EntityList;