import React,
{
    Component,
    createElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { connect } from 'react-redux';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';

import AppBar from './AppBar';
import Menu from './Menu';
import Sidebar from './Sidebar';

import { Notification } from 'react-admin';
import { Error } from 'react-admin';

import { darkTheme, lightTheme } from './themes';
import { ThemeProvider } from '@material-ui/styles';

import { withStyles, createStyles, createMuiTheme } from '@material-ui/core/styles';
import compose from 'recompose/compose';

const styles = theme => createStyles(
	{
		root:
		{
			display: 'flex',
			flexDirection: 'column',
			zIndex: 1,
			minHeight: '100vh',
			backgroundColor: theme.palette.background.body,
			position: 'relative',
			minWidth: 'fit-content',
			width: '100%',
		},
		appFrame:
		{
			display: 'flex',
			flexDirection: 'column',
		},
		contentWithSidebar:
		{
			display: "table",
			flexGrow: "unset",
			width: "100%",
			height: "100%",
			minHeight: "100vh",
		},
		contentContainer:
		{
			width: "100%",
			display: "table-cell",
			flexGrow: "unset",
			flexBasis: "unset",
			flexDirection: "unset",
			verticalAlign: "top",
		},
		content:
		{
			padding: "20px",
		},
	}
);

const sanitizeRestProps = ( {
    staticContext,
    history,
    location,
    match,
    ...props
} ) => props;

class Layout extends Component
{
	state = { hasError: false, errorMessage: null, errorInfo: null };

	constructor( props )
	{
		super( props );

		props.history.listen(
			() =>
			{
				if( this.state.hasError )
				{
					this.setState( { hasError: false } );
				}
			}
		);
	}

	componentDidCatch( errorMessage, errorInfo )
	{
		this.setState( { hasError: true, errorMessage, errorInfo } );
	}

	render()
	{
		const {
			appBar,
			children,
			classes,
			className,
			customRoutes,
			error,
			dashboard,
			logout,
			menu,
			notification,
			open,
			sidebar,
			title,
			...props
		} = this.props;

		const { hasError, errorMessage, errorInfo } = this.state;

		return (
			<div
				className={classnames( 'layout', classes.root, className )}
				{...sanitizeRestProps( props )}
			>
				<div className={classes.appFrame}>
					<main className={classes.contentWithSidebar}>
						{createElement( sidebar, {
							children: createElement( menu, {
								logout,
								hasDashboard: !!dashboard,
							} ),
						} )}
						<div className={classes.contentContainer}>
							{createElement( appBar, { title, open, logout } )}
							<div className={classes.content}>
							{hasError
								? createElement( error, {
									error: errorMessage,
									errorInfo,
									title,
								} )
								: children}
							</div>
						</div>
					</main>
					{createElement( notification )}
				</div>
			</div>
		);
	}
}

Layout.defaultProps =
{
    appBar: AppBar,
    menu: Menu,
    sidebar: Sidebar,
	notification: Notification,
    error: Error,
};

const mapStateToProps = state => (
	{
		open: state.admin.ui.sidebarOpen,
		theme: state.theme === 'dark' ? darkTheme : lightTheme,
	}
);

const EnhancedLayout = compose(
    connect(
        mapStateToProps,
        {} // Avoid connect passing dispatch in props
    ),
    withRouter,
    withStyles( styles )
)( Layout );

const LayoutWithTheme = ( { theme: themeOverride, ...props } ) =>
{
    const themeProp = useRef( themeOverride );
    const [ theme, setTheme ] = useState( createMuiTheme( themeOverride ) );

    useEffect(
		() =>
		{
			if( themeProp.current !== themeOverride )
			{
				themeProp.current = themeOverride;

				setTheme( createMuiTheme( themeOverride ) );
			}
		},
		[ themeOverride, themeProp, theme, setTheme ]
	);

    return (
        <ThemeProvider theme={theme}>
            <EnhancedLayout {...props} />
        </ThemeProvider>
    );
};

LayoutWithTheme.defaultProps =
{
    theme: lightTheme,
};

export default LayoutWithTheme;
