import React from 'react';
import {
    Create,
    TextInput,
	SimpleForm,
} from 'react-admin';

const CategoryCreate = ({ ...props }) => (
	<Create {...props} undoable={false}>
		<SimpleForm variant="outlined">
			<TextInput source="name" />
			<TextInput source="slug" />
		</SimpleForm>
	</Create>
);

export default CategoryCreate;