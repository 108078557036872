import React, { Fragment } from 'react';
import
{
	Datagrid,
	TextField,
	EditButton,
	List,
	Filter,
	SearchInput,
} from 'react-admin';
import { Route } from 'react-router';
import EntityLinkField from '../../../Components/EntityLinkField';
import TariffOptionsForm from './TariffOptionsForm';

const ListFilter = ({ classes, ...props }) => (
    <Filter {...props} variant="outlined">
        <SearchInput source="q" alwaysOn />
    </Filter>
);

const TariffOptionsList = ({ ...props }) => (
	<Fragment>
		<List {...props} sort={{ field: 'id', order: 'ASC' }} perPage={25} filters={<ListFilter />} hasCreate undoable="false">
            <Datagrid>
                <EntityLinkField source="name" />
                <TextField source="slug" />
                <EditButton />
            </Datagrid>
        </List>
	    <Route path="/admin/tariffs/options/:id" render={( { match } ) => ( <TariffOptionsForm {...props} match={match} /> )} />
	</Fragment>
);

export default TariffOptionsList;