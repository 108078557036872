import React, { Children, cloneElement, Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { useCheckMinimumRequiredProps, useCreateController } from 'ra-core';
import { TitleForRecord } from 'ra-ui-materialui';

const Create = props => ( <CreateView {...props} {...useCreateController( props )} /> );

Create.propTypes =
{
	actions: PropTypes.element,
	aside: PropTypes.element,
	children: PropTypes.element,
	classes: PropTypes.object,
	className: PropTypes.string,
	hasCreate: PropTypes.bool,
	hasEdit: PropTypes.bool,
	hasShow: PropTypes.bool,
	resource: PropTypes.string.isRequired,
	title: PropTypes.node,
	record: PropTypes.object,
	hasList: PropTypes.bool,
	successMessage: PropTypes.string,
};

const useStyles = makeStyles( theme => (
{
	root: {},
	main:
	{
		display: 'flex',
	},
	noActions:
	{
		[ theme.breakpoints.up( 'sm' ) ]:
		{
			marginTop: '1em',
		},
	},
	card:
	{
		flex: '1 1 auto',
	},
} ) );

const sanitizeRestProps = ( {
	actions,
	children,
	className,
	crudCreate,
	loading,
	loaded,
	saving,
	resource,
	title,
	hasCreate,
	hasEdit,
	hasList,
	hasShow,
	match,
	location,
	history,
	options,
	locale,
	permissions,
	successMessage,
	...rest
} ) => rest;

export const CreateView = props =>
{
	const {
		actions,
		aside,
		basePath,
		children,
		classes: classesOverride,
		className,
		defaultTitle,
		hasList,
		hasShow,
		record = {},
		redirect,
		resource,
		save,
		saving,
		title,
		version,
		...rest
	} = props;

	useCheckMinimumRequiredProps( 'Create', [ 'children' ], props );
	
	const classes = useStyles( { classes: classesOverride } );
	
	return (
		<Fragment>
			<div className={classnames( 'create-page', classes.root, className )} {...sanitizeRestProps( rest )}>
				<TitleForRecord title={title} record={record} defaultTitle={defaultTitle} />
				{actions && cloneElement( actions,
					{
						basePath,
						resource,
						hasList,
						//  Ensure we don't override any user provided props
						...actions.props,
					}
				)}
				<div className={classnames( classes.main, { [ classes.noActions ]: !actions } )}>
					{cloneElement( Children.only( children ), {
						basePath,
						record,
						redirect: typeof children.props.redirect === 'undefined'
							? redirect
							: children.props.redirect,
						resource,
						save,
						saving,
						version,
					} )}
				</div>
			</div>
		</Fragment>
	);
};

CreateView.propTypes =
{
	actions: PropTypes.element,
	aside: PropTypes.element,
	basePath: PropTypes.string,
	children: PropTypes.element,
	classes: PropTypes.object,
	className: PropTypes.string,
	defaultTitle: PropTypes.any,
	hasList: PropTypes.bool,
	hasShow: PropTypes.bool,
	record: PropTypes.object,
	redirect: PropTypes.oneOfType( [PropTypes.string, PropTypes.bool] ),
	resource: PropTypes.string,
	save: PropTypes.func,
	title: PropTypes.node,
};

CreateView.defaultProps =
{
	classes: {},
};

export default Create;