import React from 'react';
import {
    NumberInput,
    ArrayInput,
	ReferenceInput,
	SelectInput
} from 'react-admin';
import { Edit } from '../../Components/Edit';
import { Form, Section, TableFormIterator } from '../../Components/Form';
import PostTitle from './PostTitle';

const TariffEdit = ({ ...props }) => (
	<Edit {...props} undoable={false}>
		<Form titleInput={<PostTitle />}>
			<Section title="Tariff options">
				<ArrayInput source="options" variant="outlined" label={false}>
					<TableFormIterator disableIndex>
						<ReferenceInput fullWidth label="Option" source="tariff_option_id" reference="admin/tariffs/options">
							<SelectInput source="name" optionText={u => `#${u.id} - ${u.name} (${u.slug})`} />
						</ReferenceInput>
						<NumberInput label="Quote" source="quote" variant="outlined" />
					</TableFormIterator>
				</ArrayInput>
			</Section>
		</Form>
	</Edit>
);

export default TariffEdit;