import React from 'react';
import get from 'lodash/get';
import { Typography } from '@material-ui/core';

const TextField = ( { value, color } ) => ( <Typography component="span" variant="body2" style={{ color: color }}>{value}</Typography> );

const BalanceField = ( { positive, negative, zero, ...props } ) =>
{
	const value = get( props.record, props.source );

	if( value > 0 )
	{
		return ( <TextField color={positive} value={"+" + value.toLocaleString()} /> );
	}
	else if( value < 0 )
	{
		return ( <TextField color={negative} value={value.toLocaleString()} /> );
	}

	return ( <TextField color={zero} value={value.toLocaleString()} /> );
};

BalanceField.defaultProps =
{
	positive: "#008000",
	negative: "#FF0000",
	zero:     "#000000",
    addLabel: true,
};

export default BalanceField;