import React from 'react';

const Logo = ( { size = 42, ...props } ) => (
	<svg space="preserve" width={size} height={size}
			style={
				{
					shapeRendering: "geometricPrecision",
					textRendering: "geometricPrecision",
					imageRendering: "optimizeQuality",
					fillRule: "evenodd",
					clipRule: "evenodd"
				}
			}
			viewBox="0 0 2992 2784" {...props}
		>
		<path fill="currentColor" d="M2270 2016l286 0c33,0 59,27 59,59l0 286c0,32 -26,59 -59,59l-286 0c-32,0 -59,-27 -59,-59l0 -286c0,-32 27,-59 59,-59zm-59 -395c0,-32 27,-59 59,-59l286 0c33,0 59,27 59,59l0 286c0,33 -26,59 -59,59l-286 0c-32,0 -59,-26 -59,-59l0 -286zm0 -468c0,-33 27,-59 59,-59l286 0c33,0 59,26 59,59l0 285c0,33 -26,60 -59,60l-286 0c-32,0 -59,-27 -59,-60l0 -285z" />
		<path fill="currentColor" d="M365 2613c-107,0 -194,-87 -194,-194l0 -1930c0,-107 87,-194 194,-194l398 0 0 -171 -398 0c-201,0 -365,164 -365,365l0 1930c0,201 164,365 365,365l398 0 0 -171 -398 0z" />
		<path fill="currentColor" d="M2627 124l-423 0 0 171 423 0c107,0 194,87 194,194l0 1930c0,107 -87,194 -194,194l-423 0 0 171 423 0c201,0 365,-164 365,-365l0 -1930c0,-201 -164,-365 -365,-365z" />
		<path fill="currentColor" d="M891 1039l286 0c32,0 59,-27 59,-59l0 -286c0,-32 -27,-59 -59,-59l-286 0c-32,0 -59,27 -59,59l0 286c0,32 27,59 59,59z" />
		<path fill="currentColor" d="M832 1438c0,33 27,60 59,60l286 0c32,0 59,-27 59,-60l0 -285c0,-33 -27,-59 -59,-59l-286 0c-32,0 -59,26 -59,59l0 285z" />
		<path fill="currentColor" d="M718 1094l-285 0c-33,0 -59,26 -59,59l0 285c0,33 26,60 59,60l285 0c18,0 34,-9 45,-21 9,-11 14,-24 14,-39l0 -285c0,-15 -5,-28 -14,-38 -11,-13 -27,-21 -45,-21z" />
		<path fill="currentColor" d="M832 1907c0,33 27,59 59,59l286 0c32,0 59,-26 59,-59l0 -286c0,-32 -27,-59 -59,-59l-286 0c-32,0 -59,27 -59,59l0 286z" />
		<path fill="currentColor" d="M718 1562l-285 0c-33,0 -59,27 -59,59l0 286c0,33 26,59 59,59l285 0c18,0 34,-8 45,-21 9,-10 14,-23 14,-38l0 -286c0,-14 -5,-27 -14,-38 -11,-12 -27,-21 -45,-21z" />
		<path fill="currentColor" d="M1350 1966l285 0c33,0 59,-26 59,-59l0 -286c0,-32 -26,-59 -59,-59l-285 0c-33,0 -59,27 -59,59l0 286c0,33 26,59 59,59z" />
		<path fill="currentColor" d="M1812 1039l285 0c33,0 59,-27 59,-59l0 -286c0,-32 -26,-59 -59,-59l-285 0c-33,0 -59,27 -59,59l0 286c0,32 26,59 59,59z" />
		<path fill="currentColor" d="M1291 344c0,33 26,60 59,60l285 0c33,0 59,-27 59,-60l0 -230 0 -55c0,-33 -26,-59 -59,-59l-285 0c-33,0 -59,26 -59,59l0 55 0 230z" />
		<path fill="currentColor" d="M1753 1438c0,33 26,60 59,60l285 0c33,0 59,-27 59,-60l0 -285c0,-33 -26,-59 -59,-59l-285 0c-33,0 -59,26 -59,59l0 285z" />
		<path fill="currentColor" d="M2270 1498l286 0c33,0 59,-27 59,-60l0 -285c0,-33 -26,-59 -59,-59l-286 0c-32,0 -59,26 -59,59l0 285c0,33 27,60 59,60z" />
		<path fill="currentColor" d="M1753 1907c0,33 26,59 59,59l285 0c33,0 59,-26 59,-59l0 -286c0,-32 -26,-59 -59,-59l-285 0c-33,0 -59,27 -59,59l0 286z" />
		<path fill="currentColor" d="M2270 1966l286 0c33,0 59,-26 59,-59l0 -286c0,-32 -26,-59 -59,-59l-286 0c-32,0 -59,27 -59,59l0 286c0,33 27,59 59,59z" />
		<path fill="currentColor" d="M718 2016l-285 0c-33,0 -59,27 -59,59l0 286c0,32 26,59 59,59l285 0c18,0 34,-9 45,-21 9,-11 14,-24 14,-38l0 -286c0,-14 -5,-28 -14,-38 -11,-13 -27,-21 -45,-21z" />
		<path fill="currentColor" d="M1291 2361c0,32 26,59 59,59l285 0c33,0 59,-27 59,-59l0 -286c0,-32 -26,-59 -59,-59l-285 0c-33,0 -59,27 -59,59l0 286z" />
		<path fill="currentColor" d="M1291 1439c0,32 26,59 59,59l285 0c33,0 59,-27 59,-59l0 -286c0,-33 -26,-59 -59,-59l-285 0c-33,0 -59,26 -59,59l0 286z" />
		<path fill="currentColor" d="M2270 2420l286 0c33,0 59,-27 59,-59l0 -286c0,-32 -26,-59 -59,-59l-286 0c-32,0 -59,27 -59,59l0 286c0,32 27,59 59,59z" />
	</svg>
);

export default Logo;