import React from 'react';
import get from 'lodash/get';
import { Link } from 'react-admin';

const OrganizationLinkField = ( { record, source } ) =>
{
	const value = source ? get( record, source ) : record;

	if( !value )
	{
		return null;
	}

	return <Link to={`/admin/organizations/${value.id}`}>{value.name}</Link>
}

OrganizationLinkField.defaultProps =
{
    source: null,
    addLabel: true,
	label: "Organization"
};

export default OrganizationLinkField;