import CategoryIcon from '@material-ui/icons/Bookmark';
import CategoryList from './CategoryList';
import CategoryEdit from './CategoryEdit';
import CategoryCreate from './CategoryCreate';

export default {
    icon: CategoryIcon,
    list: CategoryList,
    edit: CategoryEdit,
    create: CategoryCreate,
};
