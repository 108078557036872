import React from 'react';
import SmallBox from './SmallBox';

import products from '../Entity/Products';

const ProductsCountBox = ( { data } ) => (
	<SmallBox
		value={data.products || 0}
		title="Products"
		icon={<products.icon />}
		color="00a65a"
	/>
);

export default ProductsCountBox;