import React, { forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import MenuItem from './MenuItem';

const NavLinkRef = React.forwardRef( ( props, ref ) => ( <NavLink innerRef={ref} {...props} /> ) );

const MenuItemLink = forwardRef( ( { onClick, ...props }, ref ) =>
	{
		const handleMenuTap = useCallback( e => { onClick && onClick( e ); }, [ onClick ] );

		return (
			<MenuItem
				component={NavLinkRef}
				ref={ref}
				{...props}
				onClick={handleMenuTap}
			>
			</MenuItem>
		);
	}
);

MenuItemLink.propTypes =
{
	onClick: PropTypes.func,
	to: PropTypes.oneOfType( [ PropTypes.string, PropTypes.object ] ),
};

export default MenuItemLink;