import { channel } from 'redux-saga';
import { fork, take, put } from 'redux-saga/effects';
import { useWebSocket } from '.';

import { ON_IDENTITY_STATUS_CHANGED } from "../Entity/Users/SagaActions";

export const ON_CLIENT_CONNECTED = "OnClientConnected";

function* Init()
{
	const websocket = useWebSocket();

	websocket.connect();

	for( const type of [ ON_IDENTITY_STATUS_CHANGED, ON_CLIENT_CONNECTED ] )
	{
		yield fork( AddEventListener, type );
	}
}

function* AddEventListener( type )
{
	const ch = channel();
	const websocket = useWebSocket();

	websocket.addEventListener( type, 0, payload => ch.put( { type, payload } ) );

	while( true )
	{
		yield put( yield take( ch ) );
	}
}

export default function* WebSocketSaga()
{
	return yield Init();
}