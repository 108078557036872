import React from 'react';
import
{
	Datagrid,
	DateField,
	TextField,
	EditButton,
	List,
	//NumberField,
	AutocompleteInput,
	ReferenceInput,
	SearchInput,
    Filter,
} from 'react-admin';

import RolesField from './RolesField';
import UserLinkField from './UserLinkField';
import OrganizationLinkField from '../Organizations/OrganizationLinkField';

const UserFilter = ({ classes, ...props }) => (
    <Filter {...props} variant="outlined">
        <SearchInput source="q" alwaysOn />
        <ReferenceInput label="Organization" source="OrganizationID" reference="admin/organizations">
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

const UserList = ( { classes, ...props } ) => (
	<List {...props} sort={{ field: 'id', order: 'ASC' }} perPage={25} filters={<UserFilter />} undoable="false">
		<Datagrid>
			<UserLinkField />
			<OrganizationLinkField source="organization" />
			<TextField source="email" />
			<RolesField label="Roles" />
			<DateField source="created_at" type="date" showTime />
			<DateField source="updated_at" type="date" showTime />
			<EditButton />
		</Datagrid>
	</List>
);

export default UserList;