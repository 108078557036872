import React from 'react';

import
{
	Datagrid,
	Edit,
	EditButton,
	TextField,
	ReferenceManyField,
	SimpleForm,
	TextInput,
} from 'react-admin';

import ThumbnailField from '../Products/ThumbnailField';
import ProductRefField from '../Products/ProductRefField';

const CategoryEdit = props => (
	<Edit {...props}>
		<SimpleForm variant="outlined">
			<TextInput source="name" />
			<TextInput source="slug" />
			<ReferenceManyField reference="admin/products" target="CategoryID" label="Products" perPage={5} fullWidth={true}>
				<Datagrid>
					<ThumbnailField />
					<ProductRefField source="name" />
					<TextField source="status" />
					<EditButton />
				</Datagrid>
			</ReferenceManyField>
		</SimpleForm>
	</Edit>
);

export default CategoryEdit;