import React from 'react';
import
{
	Datagrid,
	DateField,
	NumberField,
	EditButton,
	List,
	AutocompleteInput,
	ReferenceInput,
	SearchInput,
    Filter,
} from 'react-admin';

import EntityLinkField from '../../Components/EntityLinkField';
import DeviceOnlineField from './DeviceOnlineField';

const DeviceFilter = ({ classes, ...props }) => (
    <Filter {...props} variant="outlined">
        <SearchInput source="q" alwaysOn />
        <ReferenceInput label="Organization" source="OrganizationID" reference="admin/organizations">
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

const DeviceList = ( { ...props } ) => (
	<List {...props} sort={{ field: 'id', order: 'ASC' }} perPage={25} filters={<DeviceFilter />} undoable="false">
		<Datagrid>
			<NumberField source="id" />
			<DeviceOnlineField label="Status" />
			<EntityLinkField source="name" />
			<EntityLinkField label="Organization" source="name" target="organization" basePath="/admin/organizations" />
			<DateField source="created_at" type="date" showTime />
			<DateField source="updated_at" type="date" showTime />
			<EditButton />
		</Datagrid>
	</List>
);

export default DeviceList;