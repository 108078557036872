import React from 'react';
import {
    Edit,
	SimpleForm,
    TextInput,
} from 'react-admin';

import PermissionsInput from './PermissionsInput';

const RoleEdit = ({ ...props }) => (
	<Edit {...props} undoable={false}>
		<SimpleForm variant="outlined">
			<TextInput source="name" />
			<TextInput source="color" />
			<PermissionsInput />
		</SimpleForm>
	</Edit>
);

export default RoleEdit;