import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@material-ui/core';

import MenuItemLink from './MenuItemLink';

const DashboardIcon = ( { ...props } ) => (
    <SvgIcon viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" {...props}>
        <line x1="4" y1="21" x2="4" y2="14"></line>
        <line x1="4" y1="10" x2="4" y2="3"></line>
        <line x1="12" y1="21" x2="12" y2="12"></line>
        <line x1="12" y1="8" x2="12" y2="3"></line>
        <line x1="20" y1="21" x2="20" y2="16"></line>
        <line x1="20" y1="12" x2="20" y2="3"></line>
        <line x1="1" y1="14" x2="7" y2="14"></line>
        <line x1="9" y1="8" x2="15" y2="8"></line>
        <line x1="17" y1="16" x2="23" y2="16"></line>
    </SvgIcon>
);

const DashboardMenuItem = ( { locale, onClick, ...props } ) => (
    <MenuItemLink
        onClick={onClick}
        to="/"
        primaryText="Dashboard"
        leftIcon={<DashboardIcon />}
        exact
        dense
        {...props}
    />
);

DashboardMenuItem.propTypes =
{
    classes: PropTypes.object,
    locale: PropTypes.string,
    onClick: PropTypes.func,
};

export default DashboardMenuItem;