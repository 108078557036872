import React from 'react';

import RoleField from './RoleField';

const RolesField = ({ record }) => (
    <span>
		{record.roles && record.roles.map( role => <RoleField key={role.id} record={role} /> )}
    </span>
);

export default RolesField;