import React from 'react';
import {
    ReferenceInput,
    SelectInput,
    TextInput,
	ImageInput,
	ImageField,
} from 'react-admin';

import { passwordValidator, passwordMatchValidator } from '../../Validators/PasswordValidator';
import RolesInput from './RolesInput';
import { Edit } from '../../Components/Edit';
import { Form, Section } from '../../Components/Form';

export default ( { ...props } ) => (
	<Edit {...props} undoable={false}>
		<Form variant="outlined">
			<Section title="Thumbnail" sidebar>
				<ImageInput source="avatar" label={false} accept="image/*">
					<ImageField source="absolute_url" title="title" />
				</ImageInput>
			</Section>

			<Section title="Identity" sidebar>
				<ReferenceInput label="Organization" source="organization.id" reference="admin/organizations">
						<SelectInput source="name" />
				</ReferenceInput>
				<TextInput source="email" required />
				<TextInput source="password" type="password" validate={passwordValidator} />
				<TextInput source="configrm_password" type="password" validate={passwordMatchValidator} />
				<RolesInput />
			</Section>
		</Form>
	</Edit>
);