import React from 'react';
import { Link } from 'react-router-dom';

const ProductRefField = ({ record }) => (
    <Link to={`/admin/products/${record.id}`}>{record.name}</Link>
);

ProductRefField.defaultProps =
{
    source: 'id',
    label: 'Reference',
};

export default ProductRefField;