
const passwordValidator = ( value ) =>
{
	return null;
}

const passwordMatchValidator = ( value, record ) =>
{
	if( !record.password )
	{
		return null;
	}

	if( value !== record.password )
	{
		return "Passwords does not match";
	}

	return null;
}

export { passwordValidator, passwordMatchValidator };
