import React, { Children, cloneElement, Component, Fragment } from 'react';
import { KeyboardArrowLeft, ExpandMore } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import {
	Collapse,
	List,
} from '@material-ui/core';

import MenuItem from './MenuItem';

const styles =
{
	active:
	{
		color: "#FFFFFF",
	},
	submenuList:
	{
		background: "#2c3b41",
	},
};

class SubMenu extends Component
{
	state =
	{
		isOpen: false,
	};

	handleToggle = () =>
	{
		this.setState( { isOpen: !this.state.isOpen } );
	}

	render()
	{
		const { open, onClick, primaryText, leftIcon, classes, children, className, ...props } = this.props;
		const { isOpen } = this.state;

		return (
			<Fragment>
				<MenuItem
					leftIcon={leftIcon}
					rightIcon={isOpen ? <ExpandMore /> : <KeyboardArrowLeft />}
					primaryText={primaryText}
					onClick={this.handleToggle}
					open={open}
					{...props}
				/>
				<Collapse in={isOpen} timeout="auto" unmountOnExit>
					<List dense component="div" disablePadding className={classes.submenuList}>
						{Children.map( children, menuItem => cloneElement( menuItem,
								{
									open: open,
									onClick: onClick,
									activeClassName: classes.active,
								}
							)
						)}
					</List>
				</Collapse>
			</Fragment>
		);
	}
}

export default withStyles( styles )( SubMenu );