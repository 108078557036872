import React, { Children } from 'react';
import { FormInput } from 'ra-ui-materialui';
import { withStyles, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles =
{
	container:
	{
		marginBottom: 20,
		width: "100%",
	},
	summary:
	{
	},
	heading:
	{
		fontWeight: 'bold',
	},
	details:
	{
		borderTop: 'solid 1px rgba(0, 0, 0, 0.12)',
		display: "block",
	},
};

const Section = ( { title, basePath, record, resource, children, classes, toolbar, expanded = true, ...props } ) => (

	<ExpansionPanel className={classes.container} defaultExpanded={expanded}>
        <ExpansionPanelSummary className={classes.summary} expandIcon={<ExpandMoreIcon />}>
			{ title && <Typography className={classes.heading}>{title}</Typography> }
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
			{Children.map( children, input => (
				<FormInput
					basePath={basePath}
					input={input}
					record={record}
					resource={resource}
					variant="outlined"
				/>
			) )}
        </ExpansionPanelDetails>
		{toolbar}
    </ExpansionPanel>
);

export default withStyles( styles )( Section );