import React from 'react';
import SmallBox from './SmallBox';

import users from '../Entity/Users';

const UsersCountBox = ( { data } ) => (
	<SmallBox
		value={data.users || 0}
		title="Users registered"
		icon={<users.icon />}
		color="dd4b39"
	/>
);

export default UsersCountBox;