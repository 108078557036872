import React, { useState } from 'react';
import Box from './Box';
import { List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core';
import {
 Xml      as XmlIcon,
 Server   as ServerIcon,
 React    as ReactIcon,
 Database as DatabaseIcon,
 CSharp   as CSharpIcon,
}
from '../Components/Icons';

const RightNowBox = () => 
{
	const [ state, setState ] = useState( {} );

	const onLoad = ( data ) =>
	{
		setState( data );
	};

	const { version = "0.0.0", platform = "N/A", dotnet = "N/A", database = "N/A" } = state;

	return (
		<Box title="System info" url="/admin/version" onLoad={onLoad}>
			<List disablePadding>
			  <ListItem disableGutters>
				<ListItemAvatar>
				  <Avatar>
					<XmlIcon />
				  </Avatar>
				</ListItemAvatar>
				<ListItemText primary="Version" secondary={version} />
			  </ListItem>
			  <ListItem disableGutters>
				<ListItemAvatar>
				  <Avatar>
					<ServerIcon />
				  </Avatar>
				</ListItemAvatar>
				<ListItemText primary="Platform" secondary={platform} />
			  </ListItem>
			  <ListItem disableGutters>
				<ListItemAvatar>
				  <Avatar>
					<CSharpIcon />
				  </Avatar>
				</ListItemAvatar>
				<ListItemText primary=".NET Core" secondary={dotnet} />
			  </ListItem>
			  <ListItem disableGutters>
				<ListItemAvatar>
				  <Avatar>
					<ReactIcon />
				  </Avatar>
				</ListItemAvatar>
				<ListItemText primary="React.JS" secondary={React.version} />
			  </ListItem>
			  <ListItem disableGutters>
				<ListItemAvatar>
				  <Avatar>
					<DatabaseIcon />
				  </Avatar>
				</ListItemAvatar>
				<ListItemText primary="Database" secondary={database} />
			  </ListItem>
			</List>
		</Box>
	);
}

export default RightNowBox;