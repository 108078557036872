import * as Cookie from "../Cookie";
import { Fetch } from "../Services";

export const AUTH_COOKIE_NAME = "Authorization";

const AuthProvider =
{
    login: ( { username, password } ) =>
    {
		return fetch( process.env.PUBLIC_URL + "/api/login",
				{
					method: 'POST',
					body: JSON.stringify( { email: username, password: password } ),
					headers:
					{
						'Accept': 'application/json',
						'Content-Type': 'application/json',
					},
				}
			)
			.then( response => response.json() )
			.then(
				json =>
				{
					if( json.message != null )
					{
						throw new Error( json.message );
					}

					if( json.token == null )
					{
						throw new Error();
					}

					const date = new Date();

					date.setDate( date.getDate() + 7 );

					Cookie.Set( AUTH_COOKIE_NAME, json.token, { expires: date } );

					return Promise.resolve();
				}
			);
    },

    logout: () =>
	{
		Cookie.Remove( AUTH_COOKIE_NAME );

		return Promise.resolve();
	},

    checkAuth: ( params ) =>
	{
		return Fetch()
			.catch( x =>
				{
					Cookie.Remove( AUTH_COOKIE_NAME );

					return Promise.reject();
				} );
	},

    checkError: ( error ) => 
	{
		const status = error.status;

		if( status === 401 )
		{
			Cookie.Remove( AUTH_COOKIE_NAME );

			return Promise.reject();
		}

		return Promise.resolve();
	},

    getPermissions: () => Promise.resolve(),
};

export default AuthProvider;