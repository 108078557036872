import React, { cloneElement } from 'react';
import { withStyles } from '@material-ui/styles';
import { Card, CardContent, Typography } from '@material-ui/core';

const styles =
{
	root:
	{
		borderRadius: 2,
		position: 'relative',
		display: 'block',
		marginBottom: 20,
		boxShadow: '0 1px 1px rgba( 0, 0, 0, 0.1 )',
	},

	container:
	{
		position: 'relative',
	},
	
	value:
	{
		fontSize: 38,
		fontWeight: 'bold',
		margin: '0 0 10px 0',
		whiteSpace: 'nowrap',
		padding: 0,
		color: '#fff',
	},

	title:
	{
		fontSize: 15,
		color: '#fff',
	},

	icon:
	{
		transition: 'all .3s linear',
		position: 'absolute',
		top: 10,
		right: 10,
		zIndex: 0,
		fontSize: 90,
		color: 'rgba(0, 0, 0, 0.15)',
	},

	footer:
	{
		position: 'relative',
		textAlign: 'center',
		padding: '3px 0',
		color: 'rgba(255, 255, 255, 0.8)',
		display: 'block',
		zIndex: 10,
		background: 'rgba(0, 0, 0, 0.1)',
		textDecoration: 'none',
	},
};

const SmallBox = ( { classes, title = 'Unk', value = 'N/A', icon = null, color = 'ffffff' } ) => (
	<Card className={classes.root} style={{ backgroundColor: `#${color}` }}>
		<CardContent className={classes.container}>
			<Typography variant="h5" component="div" className={classes.value}>
				{value}
			</Typography>
			{icon && ( cloneElement( icon, { className: classes.icon } ) )}
			<Typography variant="body2" component="p" className={classes.title}>
				{title}
			</Typography>
		</CardContent>
	</Card>
);

export default withStyles( styles )( SmallBox );