import React from 'react';
import {
    Create,
    TextInput,
	SimpleForm,
} from 'react-admin';

const ResourceEdit = ({ ...props }) => (
    <Create {...props}>
        <SimpleForm variant="outlined">
			<TextInput source="name" />
		</SimpleForm>
    </Create>
);

export default ResourceEdit;