import React from 'react';
import SmallBox from './SmallBox';

import resources from '../Entity/Resources';

const ResourcesCountBox = ( { data } ) => (
	<SmallBox
		value={data.resources || 0}
		title="Resources"
		icon={<resources.icon />}
		color="f39c12"
	/>
);

export default ResourcesCountBox;