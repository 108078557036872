import React, { useState, useEffect, Fragment } from 'react';
import { makeStyles, Button } from '@material-ui/core';
import
{
	ArrayField,
	Datagrid,
	TextField,
	DateField,
	useVersion,
} from 'react-admin';
import LogDialog from './LogDialog';
import { Fetch, FetchJson } from "../../Services";

const useStyles = makeStyles( ( theme ) => (
{
	root:
	{
		width: "100%",
	},
} ) );

const ActionField = ( { onClick, onDownloadClick, ...props } ) =>
{
	return (
		<Fragment>
			<Button variant="text" onClick={() => onClick( props.record )}>Show</Button>
			<Button variant="text" onClick={() => onDownloadClick( props.record )}>Download</Button>
		</Fragment>
	);
};

const TelemetryLogsSection = ( { ...props } ) =>
{
	const classes = useStyles();
	const version = useVersion();
	const [ state, setState ] = useState( {} );
	const [ dialog, setDialog ] = useState( { open: false, record: null } );

	const handleLogOpen = ( record ) =>
	{
		setDialog( { open: true, record } );
	};

	const handleLogDownload = ( record ) =>
	{
		Fetch( `/admin/devices/${props.record.id}/telemetry/logs/${record.id}?dl` )
			.then( response => response.blob() )
			.then( blob =>
				{
					const url = window.URL.createObjectURL( blob );
					const a = document.createElement( 'a' );
					
					a.href = url;
					a.download = `${record.name}_${record.date}.log`;
					a.click();
				}
			);
	};

	const handleClose = ( data ) =>
	{
		setDialog( { open: false, record: null } );
	};

	const fetchData = () =>
	{
		FetchJson( `/admin/devices/${props.record.id}/telemetry/logs/` )
			.then( data => setState( data ) );
	};

	useEffect( fetchData, [ version ] ); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Fragment>
			<div className={classes.root}>
				<ArrayField source="logs" label={false} record={state} fullWidth>
					<Datagrid>
						<TextField source="name" />
						<DateField source="date" showTime />
						<ActionField label="Action" onClick={handleLogOpen} onDownloadClick={handleLogDownload} />
					</Datagrid>
				</ArrayField>
			</div>
			<LogDialog {...dialog} device={props.record} onClose={handleClose} />
		</Fragment>
	);
};

export default TelemetryLogsSection;
