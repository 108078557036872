import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

const styles =
{
	label:
	{
		display: "inline",
		padding: ".2em .6em .2em",
		fontSize: "75%",
		fontWeight: "700",
		lineHeight: "1",
		color: "#ffffff",
		textAlign: "center",
		whiteSpace: "nowrap",
		verticalAlign: "baseline",
		borderRadius: ".25em",
		marginLeft: "2px",
		marginRight: "2px",
	}
};

const Label = ( { classes, color, text, style } ) =>( <span className={classes.label} style={{ backgroundColor: '#' + color, ...style }}>{text}</span> );

export default withStyles( styles )( Label );