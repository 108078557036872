import React, { useState }  from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button } from '@material-ui/core';
import { FetchJson } from "../../Services";

const BalanceDialog = ( { open, type, record, onClose } ) =>
{
	const organization_id = record.id;

	const [ amount, setAmount ]           = useState( 0 );
	const [ description, setDescription ] = useState( "" );
	const [ error, setError ]             = useState( null );

	if( type !== "add" && type !== "take" )
	{
		return null;
	}

	const handleSave = () =>
	{
		FetchJson( `/admin/organizations/account/${type}/`,
				{
					method: 'POST',
					body: JSON.stringify( { organization_id, amount, description } ),
				}
			)
			.then(
				data =>
				{
					if( typeof data.message !== "undefined" )
					{
						setError( data.message );

						return;
					}

					onClose( data );
				}
			);
	};

	const onAmountChange = ( event ) =>
	{
		setAmount( event.target.value );
	};

	const onDesciptionChange = ( event ) =>
	{
		setDescription( event.target.value );
	};

	return (
		<Dialog open={open} onClose={onClose}>
			{type === "add" && <DialogTitle>Add Balance</DialogTitle>}
			{type === "take" && <DialogTitle>Take Balance</DialogTitle>}
			<DialogContent>
				{type === "add" && <DialogContentText>Enter the value of the amount you want to add.</DialogContentText>}
				{type === "take" && <DialogContentText>Enter the value of the amount you want to withdraw.</DialogContentText>}
				{error && <DialogContentText color="primary">Error: {error}</DialogContentText>}
				<TextField margin="dense" id="amount" label="Amount" type="number" onChange={onAmountChange} autoFocus fullWidth />
				<TextField margin="dense" id="desciption" label="Desciption" type="text" onChange={onDesciptionChange} fullWidth />
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary">Cancel</Button>
				<Button onClick={handleSave} color="primary">Save</Button>
			</DialogActions>
		</Dialog>
    );
};

export default BalanceDialog;