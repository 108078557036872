import React from 'react';
import { linkToRecord } from 'ra-core';
import { Link } from 'react-admin';

const EntityLinkField = ( { basePath = '', record, source, target, emptyText = null } ) =>
{
	if( target )
	{
		record = record[ target ];
	}

	if( !record )
	{
		return emptyText;
	}

	return ( <Link to={linkToRecord( basePath, record.id )}>{record[ source ]}</Link> );
}

EntityLinkField.defaultProps =
{
	target: null,
    source: 'name',
    addLabel: true,
};

export default EntityLinkField;