import React from 'react';
import get from 'lodash/get';

import { Table, TableBody, TableHead, TableRow, TableCell, Typography, withStyles } from '@material-ui/core';

const styles = theme => (
{
	root:
	{
		flex: 0,
		display: "table",
		width: "100%",
		padding: 0,
		marginBottom: 0,
	},
} );

const TariffOptionsField = ( { source, record, classes, ...props } ) =>
{
	const values = get( record, source );

	return (
		<Table size="small" className={classes.root}>
			<TableHead>
				<TableRow>
					<TableCell variant="head">ID</TableCell>
					<TableCell variant="head">Name</TableCell>
					<TableCell variant="head">Slug</TableCell>
					<TableCell variant="head">Value / Quote</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{values && values.map( x => (
					<TableRow key={x.id}>
						<TableCell>
							<Typography variant="button">{x.id}</Typography>
						</TableCell>
						<TableCell>
							<Typography variant="body1">{x.name}</Typography>
						</TableCell>
						<TableCell>
							<Typography variant="caption">{x.slug}</Typography>
						</TableCell>
						<TableCell>
							{x.quote === 0 ? (
							<Typography variant="body2" color="textSecondary">{x.value} / Unlimited</Typography>
								) : ( 
							<Typography variant="button">{x.value} / {x.quote}</Typography>
							)}
						</TableCell>
					</TableRow>
				) )}
			</TableBody>
		</Table>
	);
};

TariffOptionsField.defaultProps =
{
    source: 'tariff_values',
    addLabel: true,
};

export default withStyles( styles )( TariffOptionsField );