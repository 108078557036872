import RoleIcon from '@material-ui/icons/Person'
import RoleList from   './RoleList';
//import RoleCreate from './RoleCreate';
import RoleEdit from   './RoleEdit';

export default {
    list   : RoleList,
    //create : RoleCreate,
    edit   : RoleEdit,
    icon   : RoleIcon,
};