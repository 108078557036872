import React from 'react';
import {
    Create,
    Edit,
    TextInput,
	SimpleForm,
} from 'react-admin';

import { useHistory } from 'react-router-dom';
import { Dialog } from '@material-ui/core';

const EditOrCreate = ( { match, children, id, ...props} ) => (
	match && match.params && match.params.id !== "create"
		? ( <Edit   {...props} title={` #${match.params.id}`} id={match.params.id} children={children} undoable={false} /> )
		: ( <Create {...props} title={` add new`} children={children} record={{ products: [] }} /> )
);

const TariffOptionsForm = ( { match, push, ...props } ) =>
{
	const history = useHistory();

	const handleClose = () =>
	{
		history.push( "/admin/tariffs/options" );
	};

	return (
		<Dialog open onClose={handleClose} fullWidth>
			<EditOrCreate {...props} match={match}>
				<SimpleForm variant="outlined">
					<TextInput source="name" fullWidth />
					<TextInput source="slug" fullWidth />
				</SimpleForm>
			</EditOrCreate>
		</Dialog>
	);
};

export default TariffOptionsForm;
