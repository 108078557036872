import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core';
import { TextInput, NumberInput } from 'react-admin';

const useStyles = makeStyles( theme => (
{
	root:
	{
		width: '100%',
		marginTop: 0,
	},
	name:
	{
		width: '70%',
		marginTop: 0,
	},
	slug:
	{
		width: '15%',
		marginTop: 0,
	},
	price:
	{
		width: '15%',
		marginTop: 0,
	},
} ), { name: 'PostTitleStyles' } );

const PostTitle = ( { ...props } ) =>
{
	const classes = useStyles();

	return (
		<Fragment className={classes.root}>
			<TextInput source="name" {...props} className={classes.name} />
			<TextInput source="slug" {...props} className={classes.slug} />
			<NumberInput source="price" {...props} className={classes.price} />
		</Fragment>
	);
};

export default PostTitle;