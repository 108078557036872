import React from 'react';
import { connect } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import { IDENTITY_STATUS_ONLINE, IDENTITY_STATUS_AWAY, IDENTITY_STATUS_DND } from "./SagaActions";

const StyledBadge = withStyles( theme => (
{
	badge:
	{
		boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
		'&::after':
		{
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			borderRadius: '50%',
			animation: '$ripple 1.2s infinite ease-in-out',
			border: '1px solid currentColor',
			content: '""',
		},
	},
	'@keyframes ripple':
	{
		'0%':
		{
			transform: 'scale(.8)',
			opacity: 1,
		},
		'100%':
		{
			transform: 'scale(2.4)',
			opacity: 0,
		},
	},
} ) )( Badge );

const BadgeOnline = withStyles( theme => (
{
	badge:
	{
		backgroundColor: '#44b700',
		color: '#44b700',
	}
} ) )( StyledBadge );

const BadgeAway = withStyles( theme => (
{
	badge:
	{
		backgroundColor: '#ffcb00',
		color: '#ffcb00',
	}
} ) )( StyledBadge );

const BadgeDnd = withStyles( theme => (
{
	badge:
	{
		backgroundColor: '#ce1b1b',
		color: '#ce1b1b',
	}
} ) )( StyledBadge );

export const getUserNameHlsColor = ( str, s = 30, l = 80 ) =>
{
	let hash = 0;

	for ( var i = 0; i < str.length; i++ )
	{
		hash = str.charCodeAt( i ) + ( ( hash << 5 ) - hash );
	}

	const h = hash % 360;

	return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

const AvatarField = ( { record, size, style, showOnline = true, ...props } ) =>
{
	if( !record || !record.avatar )
	{
		return null;
	}

	const avatar = record.avatar.id ? (
		<Avatar {...props} size={size} src={record.avatar.absolute_url} style={{ width: size, height: size, ...style }} />
	) : (
		<Avatar {...props} style={{ width: size, height: size, fontSize: ( 1.25 * ( size / 40 ) + "rem" ), backgroundColor: getUserNameHlsColor( record.name ), ...style }}>
			{( record.name.match( /\b([A-ZА-Я])/g ) || [ record.name[ 0 ] ] ).join( "" )}
		</Avatar>
	);

	if( showOnline )
	{
		const onlineStatus = props.websocket.identity.getOnlineStatus( record.id );

		if( onlineStatus === IDENTITY_STATUS_AWAY )
		{
			return ( <BadgeAway overlap="circle" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">{avatar}</BadgeAway> );
		}

		if( onlineStatus === IDENTITY_STATUS_DND )
		{
			return ( <BadgeDnd overlap="circle" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">{avatar}</BadgeDnd> );
		}

		if( onlineStatus === IDENTITY_STATUS_ONLINE )
		{
			return ( <BadgeOnline overlap="circle" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">{avatar}</BadgeOnline> );
		}
	}

	return avatar;
};

AvatarField.defaultProps =
{
    size: 30,
};

const mapStateToProps = state => ( { websocket: state.websocket } );

export default connect( mapStateToProps, undefined )( AvatarField );
