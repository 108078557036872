import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import { addField, FieldTitle } from 'ra-core';

const sanitizeRestProps = ({
    alwaysOn,
    basePath,
    component,
    defaultValue,
    formClassName,
    initializeForm,
    input,
    isRequired,
    label,
    limitChoicesToValue,
    locale,
    meta,
    options,
    optionText,
    optionValue,
    record,
    resource,
    allowEmpty,
    source,
    textAlign,
    translate,
    translateChoice,
    ...rest
}) => rest;

class PermissionInput extends Component
{
	handleChange = ( event, value ) =>
	{
		if( value )
		{
			this.props.record.permissions.push( event.target.name )
		}
		else
		{
			const index = this.props.record.permissions.indexOf( event.target.name );
 
			if( index > -1 )
			{
				this.props.record.permissions.splice( index, 1 );
			}
		}

		this.forceUpdate();
    };

	render()
	{
		const {
			record,
			className,
            id,
            input,
            isRequired,
            label,
            source,
            resource,
            options,
            fullWidth,
            meta,
            ...rest
        } = this.props;

		const value = record.permissions.find( x => x === source );

		return (
			<FormGroup className={className} {...sanitizeRestProps( rest )}>
				<FormControlLabel
					htmlFor={id}
					control={<Switch id={id} color="primary" checked={!!value} onChange={this.handleChange} name={source} {...options} />}
					label={<FieldTitle label={label} source={source} resource={resource} />}
				/>
			</FormGroup>
		);
	}
}

PermissionInput.propTypes =
{
    className: PropTypes.string,
    id: PropTypes.string,
	input: PropTypes.object,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    resource: PropTypes.string,
    source: PropTypes.string,
    options: PropTypes.object,
};

PermissionInput.defaultProps =
{
    options: {},
};

export default addField( PermissionInput );
