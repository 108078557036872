import React, { Children, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classnames from 'classnames';
import { useTranslate, useInitializeFormWithRecord, sanitizeEmptyValues } from 'ra-core';
import { getFormInitialValues, FormInput, Toolbar } from 'ra-ui-materialui';
import {
	ImageInput,
	ImageField,
} from 'react-admin';

import { Grid } from '@material-ui/core';
import { Section } from '.';
import PublishSection from './PublishSection';
import CKEditorInput from '../Input/CKEditorInput';
import PostNameInput from './PostNameInput';

const SimpleForm = ( { initialValues, defaultValue, saving, ...props } ) => {
	let redirect = useRef( props.redirect );
	// We don't use state here for two reasons:
	// 1. There no way to execute code only after the state has been updated
	// 2. We don't want the form to rerender when redirect is changed
	const setRedirect = newRedirect =>
	{
		redirect.current = newRedirect;
	};

	const translate = useTranslate();

	const finalInitialValues = getFormInitialValues( initialValues, defaultValue, props.record );

	const submit = values =>
	{
		const finalRedirect = typeof redirect.current === undefined
			? props.redirect
			: redirect.current;

		const finalValues = sanitizeEmptyValues( finalInitialValues, values );

		props.save( finalValues, finalRedirect );
	};

	return (
		<Form
			key={props.version}
			initialValues={finalInitialValues}
			onSubmit={submit}
			mutators={{ ...arrayMutators }}
			subscription={defaultSubscription}
			{...props}
			render={formProps => (
				<SimpleFormView
					saving={formProps.submitting || saving}
					translate={translate}
					setRedirect={setRedirect}
					{...props}
					{...formProps}
				/>
			)}
		/>
	);
};

const defaultSubscription =
{
	submitting: true,
	pristine: true,
	valid: true,
	invalid: true,
};

export default SimpleForm;

const SimpleFormView = ({
	basePath,
	children,
	className,
	invalid,
	form,
	pristine,
	record,
	redirect: defaultRedirect,
	resource,
	saving,
	setRedirect,
	submitOnEnter,
	toolbar,
	undoable,
	version,
	handleSubmit,
	variant,
	margin,
	thumbnail,
	titleInput,
	description,
	...rest
}) => {
	useInitializeFormWithRecord( record );

	const handleSubmitWithRedirect = useCallback(
		( redirect = defaultRedirect ) =>
		{
			setRedirect( redirect );
			handleSubmit();
		},
		[ setRedirect, defaultRedirect, handleSubmit ]
	);

	let mainChildren = [ titleInput ];

	if( description )
	{
		mainChildren.push( <CKEditorInput source="description" /> );
	}
	
	let sections = { main: [], side: [] };

	Children.forEach( children,
		( section ) =>
		{
			sections[ section.props.sidebar ? 'side' : 'main' ].push( section );
		}
	);

    return (
		<form className={classnames( 'form', className )} style={{ flexGrow: 1 }} {...sanitizeRestProps( rest )}>
			<Grid container spacing={3} key={version}>
				<Grid item xs={9}>
					{Children.map( mainChildren, input => (
						<FormInput
							basePath={basePath}
							input={input}
							record={record}
							resource={resource}
							variant="outlined"
						/>
					) )}
					{Children.map( sections.main, section => React.cloneElement( section, { basePath, record, resource } ) )}
				</Grid>
				<Grid item xs={3}>
					<PublishSection {...rest}
						toolbar={toolbar &&
							React.cloneElement( toolbar,
							{
								basePath,
								handleSubmitWithRedirect,
								handleSubmit,
								invalid,
								pristine,
								record,
								redirect: defaultRedirect,
								resource,
								saving,
								submitOnEnter,
								undoable,
							} )
						}
					/>
					{thumbnail && (
						<Section title="Thumbnail">
							<ImageInput source="thumbnail" label={false} accept="image/*">
								<ImageField source="absolute_url" title="title" />
							</ImageInput>
						</Section>
					) }
					{Children.map( sections.side, section => React.cloneElement( section, { basePath, record, resource } ) )}
				</Grid>
			</Grid>
		</form>
	);
}

SimpleForm.propTypes =
{
	basePath: PropTypes.string,
	children: PropTypes.node,
	className: PropTypes.string,
	defaultValue: PropTypes.oneOfType( [ PropTypes.object, PropTypes.func ] ), // @deprecated
	initialValues: PropTypes.oneOfType( [ PropTypes.object, PropTypes.func ] ),
	handleSubmit: PropTypes.func, // passed by react-final-form
	invalid: PropTypes.bool,
	pristine: PropTypes.bool,
	record: PropTypes.object,
	resource: PropTypes.string,
	redirect: PropTypes.oneOfType(
		[
			PropTypes.string,
			PropTypes.bool,
			PropTypes.func,
		]
	),
	save: PropTypes.func, // the handler defined in the parent, which triggers the REST submission
	saving: PropTypes.oneOfType( [ PropTypes.object, PropTypes.bool ] ),
	submitOnEnter: PropTypes.bool,
	toolbar: PropTypes.element,
	undoable: PropTypes.bool,
	validate: PropTypes.func,
	version: PropTypes.number,
	category: PropTypes.string,
	status: PropTypes.bool,
	titleInput: PropTypes.element,
	thumbnail: PropTypes.bool,
	description: PropTypes.bool,
};

SimpleForm.defaultProps =
{
	submitOnEnter: true,
	toolbar: <Toolbar />,
	category: null,
	status: false,
	thumbnail: false,
	description: true,
	titleInput: <PostNameInput source="name" />,
};

const sanitizeRestProps = ( {
	anyTouched,
	array,
	asyncBlurFields,
	asyncValidate,
	asyncValidating,
	autofill,
	blur,
	change,
	clearAsyncError,
	clearFields,
	clearSubmit,
	clearSubmitErrors,
	destroy,
	dirty,
	dirtyFields,
	dirtyFieldsSinceLastSubmit,
	dirtySinceLastSubmit,
	dispatch,
	form,
	handleSubmit,
	hasSubmitErrors,
	hasValidationErrors,
	initialize,
	initialized,
	initialValues,
	pristine,
	pure,
	redirect,
	reset,
	resetSection,
	save,
	setRedirect,
	submit,
	submitError,
	submitErrors,
	submitAsSideEffect,
	submitFailed,
	submitSucceeded,
	submitting,
	touch,
	translate,
	triggerSubmit,
	undoable,
	untouch,
	valid,
	validate,
	validating,
	_reduxForm,
	...props
}) => props;
