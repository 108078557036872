import React, { useState, Children } from 'react';
import { FormControl } from '@material-ui/core';
import { makeStyles, Grid as MuiGrid, Button } from '@material-ui/core';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import
{
	ArrayField,
	Datagrid,
	TextField,
	FunctionField,
} from 'react-admin';
import { useInput } from 'ra-core';
import { FormInput } from 'ra-ui-materialui';

import { Section } from '../../Components/Form';
import FileLinkField from "../Resources/FileLinkField";
import PackagesDialog, { PackageSize, PackageSizeField } from './PackagesDialog';
import { FetchJson } from "../../Services";

const GridItem = ( { label, value, record, render, basePath, resource, ...props } ) => (
	<FormInput
		input={<FunctionField label={label} render={render} />}
		basePath={basePath}
		record={record}
		resource={resource}
		variant="outlined"
	/>
);

const Grid = ( { children, ...props } ) => (
	<MuiGrid container spacing={0}>
		{Children.map( children, item => ( <MuiGrid item xs={12 / children.length} {...props}>{item}</MuiGrid> ) )}
	</MuiGrid>
);

const useStyles = makeStyles( ( theme ) => (
{
	root:
	{
		width: "100%",
	},
	panel:
	{
		boxShadow: "none",
	}
} ) );

const PackagesInput = ( { record, margin, ...props } ) =>
{
	const [ dialog, setDialog ] = useState( { open: false } );

	const classes = useStyles();

	const {
        input: { value, onChange },
        meta: { touched, error } 
    } = useInput( { ...props } );

	const handleClickOpen = () =>
	{
		setDialog( { open: true } );
	};

	const handleSave = ( $package ) =>
	{
		if( !$package.id )
		{
			return;
		}

		FetchJson( `/admin/packages`,
				{
					method: 'POST',
					body: JSON.stringify( $package ),
				}
			)
			.then( data => onChange( data ) );
	};

	const handleClose = ( $package ) =>
	{
		setDialog( { open: false } );
		handleSave( $package );
	};

	return (
		<Section title="Package">
			<PackagesDialog open={dialog.open} type={dialog.type} onClose={handleClose} {...props} />

			<FormControl className={classes.root} error={!!( touched && error )} fullWidth margin={margin}>
				<ExpansionPanel className={classes.panel} expanded>
					<ExpansionPanelSummary>
						<div style={{ width: '100%' }}>
							<Grid>
								<GridItem label="PACKAGE NAME" render={i => value.name} />
								<GridItem label="VERSION"      render={i => value.version} />
								<GridItem label="TOTAL SIZE"   render={i => value.dependencies && PackageSize( value.dependencies.reduce( ( s, d ) => s + d.size, value.size ) )} />
								<FunctionField label="ACTIONS" render={i => <Button color="primary" onClick={() => handleClickOpen()}>Select package</Button>} />
							</Grid>
						</div>
					</ExpansionPanelSummary>
					{value && <ExpansionPanelDetails>
						<ArrayField source="dependencies" record={value} label={false} fullWidth>
							<Datagrid>
								<TextField source="name" />
								<TextField source="version" />
								<PackageSizeField source="size" />
								<FunctionField label="File" render={i => i.file ? <FileLinkField source="file" record={i} /> : <i>{i.state}</i> } />
							</Datagrid>
						</ArrayField>
					</ExpansionPanelDetails>}
				</ExpansionPanel>
			</FormControl>
		</Section>
	);
};

export default PackagesInput;
