import { CHANGE_THEME } from './Configuration/Actions';

export default ( previousState = 'light', { type, payload } ) =>
{
	if( type === CHANGE_THEME )
	{
		return payload;
	}

	return previousState;
};