import EntityIcon   from '@material-ui/icons/Collections';
import EntityList   from './EntityList';
import EntityEdit   from './EntityEdit';
import EntityCreate from './EntityCreate';

export default {
    list  : EntityList,
    create: EntityCreate,
    edit  : EntityEdit,
    icon  : EntityIcon,
};