import React, { Component } from 'react';

import DataProvider from '../../Services/DataProvider';
import PermissionInput from './PermissionInput';

class PermissionsInput extends Component
{
	state = {};

	componentDidMount()
	{
		this.fetchData();
	}

	async fetchData()
	{
		const { data: permissions } = await DataProvider.getMany( 'admin/roles/permissions',
			{
				filter: {},
				sort: { field: 'id', order: 'ASC' },
				pagination: { page: 1, perPage: 50 },
			}
		);

		this.setState( { permissions } );
	}

	render()
	{
		const { record }      = this.props;
		const { permissions } = this.state;

		if( !permissions )
		{
			return null;
		}

		return permissions.map( x => x.id > 0 && ( <PermissionInput key={x.id} id={x.id.toString()} source={x.name} record={record} /> ) )
	}
}

export default PermissionsInput;