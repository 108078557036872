import React, { Children, cloneElement, Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class Menu extends Component
{
	static propTypes =
	{
		onMenuClick: PropTypes.func,
	};

	render()
	{
		const { onMenuClick, open, children } = this.props;

		return (
			<Fragment>
				{Children.map( children, menuItem => cloneElement( menuItem,
						{
							open: open,
							onClick: onMenuClick,
						}
					)
				)}
			</Fragment>
		);
	}
}

const mapStateToProps = state => (
	{
		open: state.admin.ui.sidebarOpen,
	}
);

export default withRouter( connect( mapStateToProps )( Menu ) );