import React from 'react';
import { Create } from '../../Components/Create';
import { Form } from '../../Components/Form';

import PackagesInput from './PackagesInput';

const EntityCreate = ( { classes, ...props } ) => (
	<Create {...props}>
		<Form category="admin/products/categories" status thumbnail>
			<PackagesInput source="resource" />
		</Form>
	</Create>
);

export default EntityCreate;